<template>
    <EmbeddedReport :src="src" />
  </template>
  <script>

  import EmbeddedReport from '../components/embedded/report'
  
  export default {
    name: 'EmbeddedSite',
    components: {
        EmbeddedReport
    },
    data() {
      return {
        src: '/view/embedded'
      }
    }
  }
  </script>
  