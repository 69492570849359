/* eslint-disable indent */
import Vue from 'vue'
import VueRouter from 'vue-router'

import DefaultLayout from '../layouts/Default'

import Services from '../views/Services'
import ServicesReact from '../views/ServicesReact'
import ComandaServicesReact from '../views/ComandaServicesReact'
import Login from '../views/Login'
import CustomersReact from '../views/CustomersReact'
import ProvidersReact from '../views/ProvidersReact'
import SuggestionsReact from '../views/SuggestionsReact'
import AccountsReact from '../views/AccountsReact'
import CreditsReact from '../views/CreditsReact'
import BlackList from '../views/BlackList'
import Logs from '../views/Logs'
import Companies from '../views/Companies'
import Map from '../views/Map'
import Logout from '../views/Logout'
import Notifications from '../views/Notifications'
import Admins from '../views/Admins'
import Promocodes from '../views/Promocodes'
import ServiceOrdersReport from '../views/ServiceOrdersReport'
import SalesDistributionReport from '../views/SalesDistributionReport'
import MasiveNotifications from '../views/MasiveNotifications'
import RatingComments from '../views/RatingComments'
import EditConfig from '../views/EditConfigFile.vue'
import Charts from '../views/Charts.vue'
import ServiceOrderPerformance from '../views/ServiceOrderPerformance.vue'
import KpiProviders from '../views/KpiProviders.vue'
import KpiClients from '../views/KPIsClients.vue'
import Sensors from '../views/Sensors.vue'
import ServiceTypes from '../views/ServiceTypes'
import Chargeback from '../views/Chargeback'
import auth from '../middleware/auth'
import login from '../middleware/login'
import roleAccess from '../middleware/roleAccess'
import Products from '../views/Products'
import CanceledServices from '../views/CanceledServices.vue'
import Recurrences from '../views/Recurrences'
import Preorders from '../views/Preorders'
import PreordersReact from '../views/PreordersReact'
import { middleware } from '../utils/router'
import Carrusel from '../views/Carrusel'
import ProductPrice from '../views/ProductPrice'
import { SERVICE_TYPES, USER_TYPES } from '../common/enums'
import firebase from '../config/firebase'
import NotFoundReact from '../views/404.vue'
import BranchesReact from '../views/BranchesReact'
import TeamReact from '../views/TeamReact'
import RolesReact from '../views/RolesReact'
import ConfigurationReact from '../views/ConfigurationReact'
import DriverUnitsReact from '../views/DriverUnitsReact'
import ChatReact from '../views/ChatReact'
import Calls from '../views/Calls'
import SubscriptionsReact from '../views/SubscriptionsReact'
import Contact from '../views/Contact'
import PlantReact from '../views/PlantReact'
import ConnectReact from '../views/ConnectReact'

import EmbeddedSite from '../views/Embedded'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: JSON.parse(localStorage.getItem('defaultRoute')),
    name: 'Home',
    component: DefaultLayout,
    children: [{
        path: '/',
        redirect: JSON.parse(localStorage.getItem('defaultRoute'))
    }, {
        path: 'services',
        name: 'Services',
        component: ServicesReact,
        meta: {
            middleware: auth
        }
    },
    {
        path: 'comanda/services',
        name: 'Comanda Services',
        component: ComandaServicesReact,
        meta: {
            middleware: auth
        }
    },
    {
        path: 'servicesOld',
        name: 'ServicesOld',
        component: Services,
        meta: {
            middleware: auth
        }
    },
    {
        path: 'servicestoctoc',
        name: 'Services Toc toc',
        component: Services,
        props: {
            serviceTypeUuid: SERVICE_TYPES.RESTAURANT.REGISTRY_UUID
        },
        meta: {
            middleware: auth
        }
    },
    {
        path: '/providers',
        name: 'Providers',
        component: ProvidersReact,
        meta: {
            middleware: auth
        }
    },
    {
        path: '/customers',
        name: 'Customers',
        component: CustomersReact,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/chat',
        name: 'ChatReact',
        component: ChatReact,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/suggestions',
        name: 'SuggestionsReact',
        component: SuggestionsReact,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/accounts',
        name: 'AccountsReact',
        component: AccountsReact,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/account-credits',
        name: 'CreditsReact',
        component: CreditsReact,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/configuration',
        name: 'Configuration',
        component: ConfigurationReact,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/configuration_2',
        name: 'Pefil Empresa',
        component: ConfigurationReact,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/branches',
        name: 'Sucursales',
        component: BranchesReact,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/units',
        name: 'Unidades',
        component: DriverUnitsReact,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/subscriptions',
        name: 'Suscripciones',
        component: SubscriptionsReact,
        meta: {
            middleware: [auth]
        }
    },
    { // ADD in router carrusel list
        path: '/carrusel',
        name: 'Carrusel',
        component: Carrusel,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/productPrice',
        name: 'Precios de Productos',
        component: ProductPrice,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/black-list',
        name: 'BlackList',
        component: BlackList,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/logs',
        name: 'Logs',
        component: Logs,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/companies',
        name: 'Companies',
        component: Companies,
        meta: {
            middleware: [auth, roleAccess],
            roles: [USER_TYPES.GENERAL_ADMIN]
        }
    },
    {
        path: '/map',
        name: 'Map',
        component: Map,
        meta: {
            middleware: [auth, roleAccess]
        }
    },
    {
        path: '/notifications',
        name: 'Notifications',
        component: Notifications,
        meta: {
            middleware: [auth, roleAccess],
            roles: [USER_TYPES.GENERAL_ADMIN]
        }
    },
    {
        path: '/masive-notifications',
        name: 'MasiveNotifications',
        component: MasiveNotifications,
        meta: {
            middleware: [auth, roleAccess],
            roles: [USER_TYPES.GENERAL_ADMIN]
        }
    },
    {
        path: '/admins',
        name: 'Admins',
        component: Admins,
        meta: {
            middleware: [auth, roleAccess],
            roles: [USER_TYPES.GENERAL_ADMIN]
        }
    },
    {
        path: '/promocodes',
        name: 'Promocodes',
        component: Promocodes,
        meta: {
            middleware: [auth, roleAccess],
            roles: [USER_TYPES.GENERAL_ADMIN]
        }
    },
    {
        path: '/sensors',
        name: 'Sensors',
        component: Sensors,
        meta: {
            middleware: [auth, roleAccess],
            roles: [USER_TYPES.GENERAL_ADMIN]
        }
    },
    {
        path: '/services-report',
        name: 'ServicesReport',
        component: ServiceOrdersReport,
        meta: {
            middleware: [auth, roleAccess],
            roles: [USER_TYPES.GENERAL_ADMIN]
        }
    },
    {
        path: '/sales-distribution',
        name: 'SalesDistribution',
        component: SalesDistributionReport,
        meta: {
            middleware: [auth, roleAccess],
            roles: [USER_TYPES.GENERAL_ADMIN]
        }
    },
    {
        path: '/rating-comments',
        name: 'RatingComments',
        component: RatingComments,
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/edit-config',
        name: 'EditConfig',
        component: EditConfig,
        meta: {
            middleware: auth
        }
    },
    {
        path: '/charts',
        name: 'Charts',
        component: Charts,
        meta: {
            middleware: auth
        }
    },
    {
        path: '/service-order-performance',
        name: 'ServiceOrderPerformance',
        component: ServiceOrderPerformance,
        meta: {
            middleware: auth
        }
    },
    {
        path: '/kpi-provider',
        name: 'KPI provider',
        component: KpiProviders,
        meta: {
            middleware: auth
        }
    },
    {
        path: '/kpi-client',
        name: 'KPI Client',
        component: KpiClients,
        meta: {
            middleware: [auth, roleAccess],
            roles: [USER_TYPES.GENERAL_ADMIN]
        }
    },
    {
        path: '/servicetypes',
        name: 'Tipos de servicios',
        component: ServiceTypes,
        meta: {
            middleware: [auth, roleAccess],
            roles: [USER_TYPES.GENERAL_ADMIN]
        }
    },
    {
        path: '/products',
        name: 'Productos',
        component: Products,
        meta: {
            middleware: [auth, roleAccess],
            roles: [USER_TYPES.GENERAL_ADMIN]
        }
    },
    {
        path: '/chargebacks',
        name: 'Contracargos',
        component: Chargeback,
        meta: {
            middleware: [auth, roleAccess],
            roles: [USER_TYPES.GENERAL_ADMIN]
        }
    },
    {
        path: '/cancelled',
        name: 'Servicios Cancelados',
        component: CanceledServices,
        meta: {
            middleware: auth
        }
    },
    {
        path: '/recurrence',
        name: 'Suscripciónes',
        component: Recurrences,
        meta: {
            middleware: auth,
            roles: [USER_TYPES.GENERAL_ADMIN]
        }
    },
    {
        path: 'preorderOld',
        name: 'PreordenesOld',
        component: Preorders,
        meta: {
            middleware: auth,
            roles: [USER_TYPES.GENERAL_ADMIN]
        }
    },
    {
        path: 'preorder',
        name: 'Preordenes',
        component: PreordersReact,
        meta: {
            middleware: auth,
            roles: [USER_TYPES.GENERAL_ADMIN]
        }
    },
    {
        path: '/team',
        name: 'Mi Equipo',
        component: TeamReact,
        meta: {
            middleware: auth
        }
    },
    {
        path: '/report',
        name: 'Reportes',
        component: EmbeddedSite,
        meta: {
            middleware: auth
        }
    },
    {
        path: '/roles',
        name: 'Seguridad',
        component: RolesReact,
        meta: {
            middleware: auth
        }
    },
    {
        path: '/calls',
        name: 'Llamadas',
        component: Calls,
        meta: {
            middleware: auth
        }
    },
    {
        path: '/contact',
        name: 'Contacto',
        component: Contact,
    },
    {
        path: '/factory',
        name: 'Planta',
        component: PlantReact,
        meta: {
            middleware: auth
        }
    },
    {
        path: '/connect',
        name: 'Connect',
        component: ConnectReact,
        meta: {
            middleware: auth
        }
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout
    },
    {
        path: '*',
        name: 'NotFound',
        component: NotFoundReact
    }
    ]
},
{
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
        middleware: login
    }
}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const middlewareHandler = middleware(router)
router.beforeEach(middlewareHandler)
router.afterEach((to, from) => {
    firebase.logEvent(firebase.analytics, 'page_view', {
        page_path: to.path,
        from: from.path
    })
})
export default router
