<template>
  <div>
    <section id="container" style="height: 750px" />
  </div>
</template>
  
<script>
  import * as pbi from "powerbi-client";
  import { mapGetters } from 'vuex'

  const USER_PARAM_SUFFIX = '$.'

  export default {
    name: 'EmbeddedReport',
    components: {
    },
    data() {
      return {
        accountCode: ''
      }
    },
    computed: {
      ...mapGetters('auth', ['userData'])
    },
    async mounted() {
      this.accountCode = this.userData.user.accountCode
      const moduleData = this.getModuleData(this.$route.path)
      const embedUrl = this.replaceReportParams(moduleData)
      const tokenData = await this.getReportToken(moduleData.requestBody)
      const config = {
            type: 'report',
            embedUrl,
            tokenType: pbi.models.TokenType.Embed,
            accessToken: tokenData.access_token,
            settings: {
                filterPaneEnabled: false,
                navContentPaneEnabled: false
            }
        }

      let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
      const dashboardContainer = document.getElementById('container');
      const dashboard = powerbi.embed(dashboardContainer, config);

      dashboard.off("loaded");
      dashboard.off("rendered");
      dashboard.on("error", function () {
        this.dashboard.off("error");
      });
    },
    methods: {
      async getReportToken(body) {
        const { data } = await this.$serverless.post(`administrator/auth/report`, body)

        return data
      },
      replaceReportParams(data) {
        const params = Object.keys(data.params)
        let reportUrl = data.report_url

        params.forEach(param => {
          reportUrl = reportUrl.replace(param, data.params[param].includes(USER_PARAM_SUFFIX) ? this.userData.user[data.params[param].replace(USER_PARAM_SUFFIX, '')] : data.params[param])
        })
        return reportUrl
      },
      getModuleData(route) {
        let data = {}
        this.userData?.modules?.some(module => {
          if (module.path == route) {
            data = module
            return true
          }

          return module?.sections.some(section => {
            if (section.path == route) {
              data = section
              return true
            }
          })
        })

        return data
      }
    }
  }
</script>
  
<style lang="scss" scoped>

</style>
  