<template>
  <div class="c-app">
    <TheSidebar />
    <div class="c-wrapper">
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade">
              <div id="parent">
                <div id="ccp"></div>
                <router-view />
              </div>
            </transition>
          </CContainer>
        </main>
      </div>
      <!-- <TheFooter /> -->
    </div>
  </div>
</template>

<script>
import TheSidebar from '../components/TheSidebar'
import TheHeader from '../components/TheHeader'
import "amazon-connect-streams"
import { getUserData } from '../utils/auth';
// import TheFooter from '../components/TheFooter'

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader
    // TheFooter
  },
  watch: {
    '$route.path'(route) {
      if (route === '/connect') {
        this.initializeCCP()
      }
    }
  },
  mounted() {
    if (this.$route.path === '/connect') {
      this.initializeCCP()
    }
  },
  methods: {
    initializeCCP() {
      const userData = JSON.parse(getUserData() || {})
      if (!userData.config?.ccpUrl) {
        return
      }
      const subdomain = userData.config?.ccpUrl || process.env.VUE_APP_CONFIG_CONNECT_CCPURL
      connect.core.initCCP(document.getElementById('ccp'), {
        ccpUrl: `https://${subdomain}.my.connect.aws/ccp-v2/`,
        region: 'us-west-2',
        loginPopup: true,
        loginPopupAutoClose: true,
        softphone: {
          allowFramedSoftphone: true
        },
        pageOptions: {
          enableAudioDeviceSettings: true,
          enablePhoneTypeSettings: true
        }
      })

      // On inbound communication
      connect.contact(contact => {
        contact.onEnded(() => {
          try {
            const site = document.getElementById('reactSite')
            if (site && site.contentWindow) {
              localStorage.setItem('contactData', JSON.stringify({ isCalling: 0 }))
              site.contentWindow.postMessage({ contactData: { isCalling: 0 } }, '*')
            }
          } catch (e) { }
        })

        // receive contact metadata
        this.contact = contact.getAttributes()
        try {
          const site = document.getElementById('reactSite')

          if (site && site.contentWindow) {
            localStorage.setItem('contactData', JSON.stringify({ ...this.contact, isCalling: 1 }))
            site.contentWindow.postMessage({ contactData: { ...this.contact, isCalling: 1 } }, '*')
          }
        } catch (e) { }
      })
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

#parent {
  height: calc(100%);
  ;
}

#ccp {
  width: 26%;
  height: calc(100% - 56px);
  position: absolute;
  display: none;
}
@media screen and (min-width: 992px) and (max-width: 1400px) {
  #ccp {
    width: calc(26% - 26px);
  }
}
@media screen and (max-width: 600px) {
  #ccp {
    width: 100%;
    text-align: center;
    position: relative;
  }
}
</style>
