<template>
<div class="services">
    <CModal
      :color="status"
      :show.sync="isModalOpened"
      :closeOnBackdrop="false"
      size="xl"
      class="service-detail"
    >
      <div tabindex="0" ref="ServicesAlertError">
        <CAlert
          :color="error.type || 'danger'"
          :closeButton="true"
          :show.sync="alertError"
        >
          {{ error.message }}
        </CAlert>
      </div>
      <div role="tablist">

        <b-tabs v-model="serviceTab" content-class="mt-3" align="center">

        <!-- Detail -->
        <b-tab
          title="Servicio" active
          v-model="collpaseOptions.details"
          class="p-3"
        >
          <div class="modal-text-wrapper">
            <div>
              <strong>Tipo de servicio: </strong
              ><span v-if="infoModal.serviceType">{{
                infoModal.serviceType
              }}</span>
            </div>
            <div v-if="!isCompanyAdmin">
              <strong>Nombre del cliente: </strong
              ><span v-if="infoModal.name">{{ infoModal.name }}</span>
            </div>
            <div v-if="!userHasCompany">
              <strong>Teléfono: </strong>
              <span class="link" @click="seeCustomerDetail()">{{ infoModal.phoneNumber || "" }}</span>
            </div>
            <Voximplant v-if="false" :number="String(infoModal.phoneNumber)"></Voximplant>
            <div v-if="!isCompanyAdmin && infoModal.userData && infoModal.userData.name">
              <strong>Creado por: </strong
              ><span>{{ infoModal.userData.name }}</span>
            </div>
            <div>
              <strong>Folio: </strong><span>{{ infoModal.folio }}</span>
            </div>
            <div>
              <strong>Día y hora: </strong>
              <date-range-picker
                ref="picker"
                v-model="editDate"
                :locale-data="{
                  format: 'dd/mm/yyyy hh:mm',
                  daysOfWeek: daysOfWeekSpanish,
                  applyLabel: 'Aplicar',
                  cancelLabel: 'Cancelar',
                }"
                :ranges="false"
                opens="center"
                :append-to-body="true"
                :single-date-picker="true"
                :time-picker="true"
                :time-picker24-hour="false"
                :date-format="disabledDates"
                class="date-range"
                @update="updateDate"
                :disabled="isCompanyAdmin || readonly ? true : false"
                v-c-tooltip="
                  'Actualiza la fecha y hora de entrega de servicio'
                "
              >
                <template v-slot:input="picker" style="min-width: 350px">
                  {{ formatToDatePicker(picker.startDate) }}
                </template>
              </date-range-picker>
            </div>
            <div>
              <strong>Prioritario: </strong
              ><span>
                <toggle-button
                  :value="infoModal.priorityFlag"
                  class="ml-1"
                  :width="85"
                  :disabled="isCompanyAdmin || readonly"
                  :labels="{
                    checked: 'Alta',
                    unchecked: 'Normal',
                  }"
                  @change="updatePriority()"
                  :key="infoModal.priorityFlag"
                />
              </span>
            </div>
            <div>
              <strong>Horario: </strong
              ><span>{{ formatScheduleDay(infoModal.scheduleDay) }}</span>
            </div>
            <div class="d-flex">
              <strong>Estatus: </strong>
              <div>
                <span
                  v-if="!updateableStatus.includes(infoModal.statusId)"
                  class="ml-1"
                >{{ infoModal.status }}
                </span>
                <toggle-button
                  v-if="updateableStatus.includes(infoModal.statusId)"
                  :value="isActive"
                  class="ml-1"
                  :width="85"
                  :color="{ checked: '#28D7D8', unchecked: '#321FDB' }"
                  :labels="{
                    checked: getSwitchLabel(infoModal.statusId, true),
                    unchecked: getSwitchLabel(infoModal.statusId),
                  }"
                  :disabled="isActive || readonly ? true : false"
                  @change="isActive = !isActive"
                  :sync="true"
                  v-c-tooltip="this.getStatusTooltip(infoModal.statusId)"
                  :key="infoModal.statusId"
                />
              </div>
              <div v-if="infoModal.statusId === 8" class="ml-1">
                <span v-if="infoModal && infoModal.cancellation && infoModal.cancellation.isFree === false">
                  con costo</span
                  >
                <span v-if="infoModal && infoModal.cancellation && infoModal.cancellation.isFree === true">
                  sin costo</span
                >
              </div>
            </div>
            <div v-if="infoModal && infoModal.cancellation">
              <strong>Razón de cancelación: </strong
              ><span class="text-capitalize">{{
                infoModal && infoModal.cancellation.reason
              }}</span>
            </div>
            <div v-if="infoModal.provider">
              <strong>Colaborador: </strong
              ><span class="text-capitalize">{{
                formatProviders(infoModal.provider.shortName)
              }}</span>
            </div>
            <div v-else>
              <strong>Colaborador: </strong
              ><span class="text-capitalize">Sin colaborador</span>
            </div>
            <div v-if="infoModal.blacklistZone === true">
              <strong>
                <CIcon name="cilLocationPin"/>
                ¡Pedido en zona de riesgo!
                <CIcon name="cilLocationPin"/>
              </strong>
            </div>
            <div>
              <strong>Restricción de Distancia: </strong
              ><span>
                <toggle-button
                  :value="infoModal.disableDistanceRestriction"
                  class="ml-1"
                  :width="85"
                  :disabled="isCompanyAdmin || readonly"
                  :labels="{
                    checked: 'Deshabilitada',
                    unchecked: 'Habilitada',
                  }"
                  @change="setDisableDistanceRestriction()"
                  :key="infoModal.disableDistanceRestriction"
                />
              </span>
            </div>
            <div v-if="infoModal && infoModal.addressChanged">
              <strong class="red-color">¡Dirección ha cambiado!</strong>
            </div>
            <div>
              <strong>Dirección: </strong
              ><span>{{ formatAddress(infoModal.address) }}</span>
            </div>
            <div
              v-if="
                STATUS_SERVICES_TO_CANCEL_BY_ADMIN.includes(
                  infoModal.statusId
                )
              "
            >
              <strong>Coordenadas: </strong
              ><span
                v-if="infoModal.address"
              ><button class="btn-primary" @click="goToGoogleMaps()">
                Google Maps
              </button></span
              >
            </div>
            <div v-if="!isCompanyAdmin">
              <strong>Tipo de domicilio: </strong
              ><span v-if="infoModal.address">{{
                infoModal.address.type
              }}</span>
            </div>
            <div>
              <strong>Referencia: </strong
              ><span v-if="infoModal.address">{{
                infoModal.address.reference
              }}</span>
            </div>
            <div
              v-if="
                !isCompanyAdmin && !readonly &&
                  STATUS_SERVICES_TO_CANCEL_BY_ADMIN.includes(
                    infoModal.statusId
                  )
              "
            >
              <PaymentType
                :isModalActive="isModalOpened"
                :serviceUuid="infoModal.registryUuid"
                :paymentType="infoModal.paymentType"
                @onSuccess="onPaymentSuccess"
                @onError="this.onErrorPayment"
              >
              </PaymentType>
            </div>
            <div v-else>
              <strong>Método de pago: </strong
              ><span>{{ formatPaymentType(infoModal.paymentType) }}</span>
            </div>
            <div v-if="isGeneralAdmin">
              <strong class="mb-5 w-25">Especificaciones:</strong>
              <div v-if="loader" class="d-flex justify-content-center align-items-center" style="height: 100px">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
              </div>
              <b-card
                v-else
                v-show="comments.length || error.message"
                :style="{height: error.message ? '48px' : comments.length <= 2 ? '77px' : '100px'}"
                class="mt-1  p-0 w-100"
                :class="{'card-error': error.message, 'card-comments': !error.message}"
              >
                <b-alert
                  class="m-0"
                  v-show="error.message"
                  show
                  variant="danger">{{ error.message }}</b-alert>
                <b-list-group v-show="!error.message" flush>
                  <b-list-group-item class="p-2" :key="comment.registryUuid" v-for="comment in comments">
                    <b-button
                      v-if="isGeneralAdmin"
                      :disabled="!isGeneralAdmin"
                      @click="deleteComment(comment.registryUuid, infoModal.registryUuid)"
                      class="button-delete-comment"
                      variant="danger"
                      size="sm"
                    >
                      X
                    </b-button>
                    <b-button
                      v-if="isGeneralAdmin"
                      :disabled="!isGeneralAdmin"
                      @click="toggleUpdateComment(comment.description, comment.registryUuid, infoModal.registryUuid)"
                      class="button-update-comment"
                      variant="primary"
                      size="sm"
                    >
                      Modificar
                    </b-button>
                    <p style="max-width: 284px" class="mb-0">{{ comment.description }}</p>
                  </b-list-group-item>
                </b-list-group>
              </b-card>
              <b-form-textarea
                maxlength="200"
                v-if="isGeneralAdmin"
                style="height: 100%; min-height: 100px; max-height: 100px; font-size: 1rem;"
                :disabled="!isGeneralAdmin"
                v-model="specsService.comment"
                class="my-2 w-100"
                size="sm"
                id="textarea-small"
                placeholder="Especificaciones del servicio"
              >
              </b-form-textarea>
            </div>
            <div v-else-if="!isGeneralAdmin && comments.length || error.message">
              <strong class="mb-5 w-25">Especificaciones:</strong>
              <div
                v-if="loader"
                class="d-flex justify-content-center align-items-center"
              >
                <b-spinner variant="primary" label="Spinning"></b-spinner>
              </div>
              <b-card
                v-show="comments.length || error.message"
                :style="{height: error.message && '69px', height: comments.length < 2 && '50px', height: comments.length <= 2 ? '77px' : '100px'}"
                v-else
                class="mt-1 p-0 w-100"
                :class="{'card-error': error.message, 'card-comments': !error.message}"
              >
                <b-alert v-show="error.message" show variant="danger">{{ error.message }}</b-alert>
                <ul style="list-style-type: disc" flush>
                  <li class="p-2" :key="comment.registryUuid" v-for="comment in comments">
                    <p style="max-width: 284px" class="mb-0">{{ comment.description }}</p>
                  </li>
                </ul>
              </b-card>
            </div>
          </div>
        </b-tab>
        <b-tab
          title="Pago"
        >
          <div v-if="showCreatePaymentLinkButton()">
              <strong>Crear Liga de Pago: </strong>
              <button
                type="button"
                class="btn-primary"
                @click="createPaymentLink()"
                v-c-tooltip="
                  'Crear liga de pago'
                "
              >
                <div v-if="isCreatingLink" class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <strong v-else>+</strong>
              </button>
            </div>
          <div v-if="!isCompanyAdmin && infoModal.payment && infoModal.payment.paymentLinkUrl">
              <strong>Liga de pago: </strong
              ><span>{{ infoModal.payment.paymentLinkUrl }}</span>
            </div>

            <div v-if="!isCompanyAdmin && infoModal.payment && infoModal.payment.paymentLinkUrl">
              <strong>Estatus de pago: </strong
              ><span>{{ this.infoModal.payment.paymentLinkStatus ? this.infoModal.payment.paymentLinkStatus : 'En progreso' }}</span>

              <button
                type="button"
                class="btn-primary ml-2"
                @click="$event => getPaymentLinkStatus(infoModal.payment.paymentLinkId)"
                v-c-tooltip="
                  'Refrescar pago'
                "
              >
                <div v-if="isUpdatingPaymentLinkStatus" class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div v-else><CIcon name="cil-reload"></CIcon></div>
              </button>
            </div>
            <div v-if="trackingLink">
              <strong>Liga de seguimiento: </strong>
              <a :href="trackingLink" target="_blank">Seguimiento de pedido</a>
            </div>
            <div
              v-if="
                !isCompanyAdmin &&
                  infoModal.paymentType === 3 &&
                  infoModal.number
              "
            >
              <strong>Número de tarjeta: </strong
              ><span>${{ infoModal.number }}</span>
            </div>
            <div
              v-if="
                !isCompanyAdmin &&
                  infoModal.paymentType === 3 &&
                  infoModal.cardHolder
              "
            >
              <strong>Dueño de tarjeta: </strong
              ><span>${{ infoModal.cardHolder }}</span>
            </div>
            <div v-if="!isCompanyAdmin">
              <strong>Dispositivo: </strong
              ><span class="text-capitalize">{{ infoModal.deviceFrom }}</span>
            </div>
            <div
              v-if="
                !isCompanyAdmin &&
                  infoModal.serviceDetails &&
                  infoModal.serviceDetails.serviceTypeCategory
              "
            >
              <strong>Categoría: </strong
              ><span class="text-capitalize">{{
                infoModal.serviceDetails.serviceTypeCategory
              }}</span>
            </div>
            <div
              v-if="
                !isCompanyAdmin &&
                  infoModal.serviceDetails &&
                  infoModal.serviceDetails.showcase
              "
            >
              <strong>Presentación: </strong
              ><span class="text-capitalize">{{
                infoModal.serviceDetails.showcase
              }}</span>
            </div>
            <div
              v-if="
                !isCompanyAdmin &&
                  infoModal.serviceDetails &&
                  infoModal.serviceDetails.option
              "
            >
              <strong>Cantidad: </strong
              ><span class="text-capitalize">{{
                infoModal.serviceDetails.option
              }}</span>
            </div>
            <div v-if="isCompanyAdmin || readonly">
              <strong>Monto solicitado: </strong
              ><span>${{ formatMoney(infoModal.amount) }}</span>
            </div>
            <div v-if="!isCompanyAdmin && !readonly" class="amount-requested">
              <strong>Monto solicitado: </strong>
              <span> $</span>
              <div class="d-flex">
                <input
                  type="text"
                  maxlength="5"
                  v-model="newAmount"
                  @keypress="onlyNumbers($event)"
                />
                <button
                  class="btn-primary d-flex"
                  @click="updateAmount()"
                  v-c-tooltip="
                    'Actualiza el monto solicitado de acuerdo a la cantidad ingresada en el campo'
                  "
                >
                  <CIcon name="cil-pencil"></CIcon>
                </button>
              </div>
            </div>
            <div v-if="!isCompanyAdmin && infoModal.fee">
              <strong>Cuota de servicio:</strong
              ><span>${{ formatMoney(removeDecimals(infoModal.fee)) }}</span>
            </div>
            <div v-if="!isCompanyAdmin && !!infoModal.shippingFee">
              <strong>Costo de Envío:</strong
              ><span>${{ formatMoney(removeDecimals(infoModal.shippingFee)) }}</span>
            </div>
            <div v-if="!isCompanyAdmin && infoModal.saturationFee">
              <strong>Tarifa dinámica:</strong
              ><span>${{ formatMoney(infoModal.saturationFee) }}</span>
            </div>
            <div
              v-if="
                !isCompanyAdmin &&
                  !infoModal.saturationFee &&
                  !infoModal.fee &&
                  infoModal.serviceFee
              "
            >
              <strong>Cuota de servicio:</strong
              ><span>${{ formatMoney(infoModal.serviceFee) }}</span>
            </div>
            <div v-if="infoModal.tip">
              <strong>Propina: </strong
              ><span>${{ formatMoney(infoModal.tip) }}</span>
            </div>
            <div
              v-if="
                !isCompanyAdmin && !readonly &&
                  STATUS_SERVICES_TO_CANCEL_BY_ADMIN.includes(
                    infoModal.statusId
                  )
              "
            >
              <Promocodes
                :isModalActive="isModalOpened"
                :serviceUuid="infoModal.registryUuid"
                :promocode="infoModal.promocode"
                @onSuccess="onPromocodeSuccess"
                @onError="this.onErrorPromocodes"
              >
              </Promocodes>
            </div>
            <div
              v-if="
                !isCompanyAdmin &&
                  !infoModal.promocode &&
                  infoModal.discount > 0
              "
            >
              <strong>Crédito de referido: </strong
              ><span>${{ formatMoney(infoModal.discount) }}</span>
            </div>
            <div
              v-if="
                !isCompanyAdmin && infoModal.discount && infoModal.promocode
              "
            >
              <strong>Descuento: </strong
              ><span>${{ formatMoney(infoModal.discount) }}</span>
            </div>
            <div>
              <strong>Total de la orden: </strong
              ><span>${{ formatMoney(infoModal.totalAmount) }}</span>
            </div>
            <div
              v-if="
                STATUS_SERVICES_TO_ASSIGN_COMPANY_DISABLED.includes(
                  infoModal.statusId
                )
              "
            >
              <strong>Comisión por unidad: </strong
              ><span>${{ formatMoney(infoModal.commissionPerLiter) }}</span>
            </div>
            <div
              v-if="
                STATUS_SERVICES_TO_ASSIGN_COMPANY_DISABLED.includes(
                  infoModal.statusId
                )
              "
            >
              <strong>Total de comisión por unidad: </strong
              ><span
              >${{ formatMoney(infoModal.totalCommissionPerLiter) }}</span
              >
            </div>
            <div v-if="infoModal.statusId == STATUS_SERVICES.FINISHED.ID">
              <strong>Tipo de compra: </strong
              ><span>{{
                !infoModal.hasBuyBack ? "Primera vez" : "Re-compra"
              }}</span>
            </div>
            <div v-if="!isCompanyAdmin">
              <strong>Compañía preferente: </strong
              ><span>{{
                infoModal.preferredCompany
                  ? infoModal.preferredCompany.name
                  : "No disponible"
              }}</span>
            </div>
            <div v-if="infoModal.notes">
              <strong>Notas: </strong
              ><span>{{ infoModal.notes }}</span>
            </div>
            <div
              v-if="
                STATUS_SERVICES_TO_ASSIGN_COMPANY.includes(
                  infoModal.statusId
                ) && !isCompanyAdmin && !readonly
              "
              class="assign-company-wrapper"
            >
              <strong class="d-block my-auto">Compañía asignada: </strong>
              <AssingServiceCompany
                :isModalActive="isModalOpened"
                :companyList="companies"
                :service="infoModal"
                @onSuccess="onSuccessCompanyAssignation"
              >
              </AssingServiceCompany>
            </div>
            <div>
              <strong>Nombre de quien recibe: </strong
              ><span>{{ getAddressContact() }}</span>
            </div>
            <div
              v-if="!isCompanyAdmin && !readonly && infoModal.paymentType === 'Tarjeta (TDEB | TCR)'"
              class="d-flex"
            >
              <strong>Solicitó contracargo: </strong>
              <div>
                <toggle-button
                  :value="infoModal.chargeback"
                  class="ml-1"
                  :width="70"
                  :color="{ checked: '#f9b115', unchecked: '#321fdb' }"
                  @change="updateServiceChargeback"
                  :sync="true"
                  v-c-tooltip="'Marca servicio como solicitud de contracargo'"
                />
              </div>
              <div v-if="infoModal.statusId === 8" class="ml-1">
                <span v-if="infoModal && infoModal.cancellation && infoModal.cancellation.isFree === false">
                  {{ infoModal.status }} con costo</span
                  >
                <span v-if="infoModal && infoModal.cancellation && infoModal.cancellation.isFree === true">
                  {{ infoModal.status }} sin costo</span
                >
              </div>
            </div>

            <div
              v-if="
                infoModal.serviceProductDetails &&
                  infoModal.serviceProductDetails.length > 0
              "
            >
              <CDataTable
                :items="this.infoModal.categories"
                :fields="detailFields"
                class="main-table"
                :clickableRows="true"
              >
                <template #option="{ item, index }">
                  <td @click="toggleDetails(item, index)" >
                    {{ item.units }}
                  </td>
                </template>
                <template #serviceTypeCategory="{ item, index }">
                  <td @click="toggleDetails(item, index)">
                    {{ item.name }}
                  </td>
                </template>
                <template #showcase="{ item, index }">
                  <td @click="toggleDetails(item, index)">
                    {{ item.showcase.name }}
                  </td>
                </template>
                <template #unitPrice="{ item, index }">
                  <td @click="toggleDetails(item, index)">
                    {{ item.unitPrice ? `$${(item.unitPrice / 100)}` : '' }}
                  </td>
                </template>
                <template #notes="{ item, index }">
                  <td
                    @click="toggleDetails(item, index)"
                    v-show="item.notes"
                  >
                    {{ item.notes }}
                  </td>
                </template>
                <template #details="{item}">
                  <CCollapse
                    v-if="item.complements.length > 0"
                    :show="Boolean(item._toggled)"
                    :duration="collapseDuration"
                  >
                    <div class="row ml-1 mb-5">
                      <div class="ml-10">
                        <tr><b>Complementos</b></tr>
                        <tr
                          v-show="item.complements.length > 0"
                          v-for="complement in item.complements"
                          :key="complement.registryUuid"
                        >
                          <tfoot>{{ complement.name }}</tfoot>
                        </tr>
                      </div>
                      <div class="ml-10">

                        <tr><b>Costo Adicional</b></tr>
                        <tr
                          v-show="item.complements.length > 0"
                          v-for="complement in item.complements"
                        >
                          ${{ complement.unitPrice / 100 }}
                        </tr>
                      </div>
                    </div>
                  </CCollapse>
                </template>
              </CDataTable>
            </div>
          </b-tab>
        <!-- Edit address(only general admin) -->
        <b-tab
          v-if="
            STATUS_SERVICES_TO_CANCEL_BY_ADMIN.includes(infoModal.statusId) &&
              !isCompanyAdmin
          "
          title="Cliente"
          id="accordion-2"
          accordion="my-accordion"
          v-model="collpaseOptions.editAddress"
          role="tabpanel"
          class="p-3 edit-address"
        >
          <form
            id="updateAdress"
            ref="form"
            v-on:submit.prevent="editAddressAction()"
          >
            <div>
              <div>
                <strong>Nombre</strong>
                <span>{{infoModal.name}}</span>
              </div>
              <div>
                <strong>Teléfono</strong>
                <span>{{infoModal.phoneNumber}}</span>
              </div>
            </div>
            <div>
              <div class="w-100 m-0">
                <label>Calle</label>
                <input
                  v-model="editAdress.street"
                  type="text"
                  placeholder="Calle"
                  maxlength="100"
                  @blur="updateMapByChangingAddress"
                  :disabled="readonly"
                  v-c-tooltip="
                    'Corresponde a la Calle de la residencia o negocio'
                  "
                  required
                />
              </div>
            </div>
            <div>
              <div>
                <label>Número exterior</label>
                <input
                  v-model="editAdress.numExt"
                  type="text"
                  placeholder="Número exterior"
                  maxlength="100"
                  @blur="updateMapByChangingAddress"
                  :disabled="readonly"
                  v-c-tooltip="'Número exterior de la residencia o negocio'"
                  required
                />
              </div>
              <div>
                <label>Número interior</label>
                <input
                  v-model="editAdress.numInt"
                  type="text"
                  placeholder="Número interior"
                  v-c-tooltip="'Número interior de la residencia o negocio'"
                  maxlength="100"
                  :disabled="readonly"
                />
              </div>
            </div>
            <div>
              <div>
                <label>Código postal</label>
                <input
                  v-model="editAdress.zipcode"
                  type="text"
                  placeholder="Código postal"
                  minlength="5"
                  maxlength="5"
                  @keypress="onlyNumbers($event)"
                  @blur="updateMapByChangingAddress"
                  v-c-tooltip="
                    'C. P. de la zona donde se entregará el servicio'
                  "
                  :disabled="readonly"
                  required
                />
              </div>
              <div>
                <label>Colonia</label>
                <input
                  v-model="editAdress.suburb"
                  type="text"
                  placeholder="Colonia"
                  maxlength="100"
                  @keypress="alphaNumeric($event)"
                  @blur="updateMapByChangingAddress"
                  v-c-tooltip="
                    'Corresponde a la Colonia de la residencia o negocio'
                  "
                  :disabled="readonly"
                  required
                />
              </div>
            </div>
            <div>
              <div>
                <label>Municipio</label>
                <input
                  v-model="editAdress.municipality"
                  type="text"
                  placeholder="Municipio"
                  maxlength="100"
                  @keypress="alphaNumeric($event)"
                  @blur="updateMapByChangingAddress"
                  v-c-tooltip="
                    'Corresponde al Municipio de la residencia o negocio'
                  "
                  :disabled="readonly"
                  required
                />
              </div>
              <div>
                <label>Estado</label>
                <input
                  v-model="editAdress.state"
                  type="text"
                  placeholder="Estado"
                  maxlength="50"
                  @keypress="onlyLetters($event)"
                  @blur="updateMapByChangingAddress"
                  v-c-tooltip="
                    'Corresponde al Estado de la residencia o negocio'
                  "
                  :disabled="readonly"
                  required
                />
              </div>
            </div>
            <div>
              <div>
                <label>Tipo de domicilio</label>
                <input
                  v-model="editAdress.type"
                  type="text"
                  placeholder="Tipo de domicilio"
                  v-c-tooltip="
                    'Corresponde al tipo de domicilio, puede ser: Casa, Edificio, Local, etc.'
                  "
                  :disabled="readonly"
                  maxlength="100"
                />
              </div>
              <div>
                <label>Referencia</label>
                <input
                  v-model="editAdress.reference"
                  type="text"
                  placeholder="Referencia"
                  v-c-tooltip="
                    'Datos de referencia para encontrar el lugar donde se entregará el servicio'
                  "
                  :disabled="readonly"
                  maxlength="255"
                />
              </div>
            </div>
            <div class="latitude-longitude">
              <div>
                <div>
                  <label>Latitud</label>
                  <button
                    type="button"
                    v-c-tooltip="
                      'Corresponde a la latitud de las coordenadas de entrega del servicio'
                    "
                  >
                    ?
                  </button>
                </div>
                <input
                  v-model="editAdressInteger.latitude"
                  type="text"
                  placeholder="Latitud"
                  maxlength="20"
                  pattern="^([+-]?([0-8]?[0-9](\.\d+)?|90(\.0*)?))$"
                  title="Latitud inválida."
                  :disabled="readonly"
                  required
                />
              </div>
              <div>
                <div>
                  <label>Longitud</label>
                  <button
                    type="button"
                    v-c-tooltip="
                      'Corresponde a la longitud de las coordenadas de entrega del servicio'
                    "
                  >
                    ?
                  </button>
                </div>
                <input
                  v-model="editAdressInteger.longitude"
                  type="text"
                  placeholder="Longitud"
                  maxlength="20"
                  pattern="^([+-]?([0-9]?[0-9](\.\d+)?|1[0-7][0-9](\.\d+)?|180(\.0+)?))$"
                  title="Longitud inválida."
                  :disabled="readonly"
                  required
                />
              </div>
            </div>
            <div>
              <GoogleMapsMark
                ref="map"
                @markChange="saveMapCoordenates"
                class="w-100"
                :readonly="readonly"
              >
              </GoogleMapsMark>
            </div>
          </form>
          <div class="edit-address-buttons">
            <div class="tabs-selector">
              <button
                @click.prevent="editByAddress = false"
                :class="editByAddress === false ? 'active' : ''"
                v-c-tooltip="
                  'Cuando está habilitada esta opción se actualiza la dirección al mover el pin del mapa. Al editar algún dato de la dirección la ubicación permanece fija.'
                "
              >
                Pin
              </button>
              <button
                @click.prevent="editByAddress = true"
                :class="editByAddress ? 'active' : ''"
                v-c-tooltip="
                  'Cuando está habilitada esta opción se actualiza la ubicación de acuerdo a la dirección ingresada en los campos. Al mover el pin se actualizan únicamente las coordenadas.'
                "
              >
                Domicilio
              </button>
            </div>
          </div>
        </b-tab>
     
        <!-- Cancel service(only general admin) -->
        <b-tab
          title="Cancelar"
          v-if="
            STATUS_SERVICES_TO_CANCEL_BY_ADMIN.includes(infoModal.statusId) &&
              !isCompanyAdmin && !readonly
          "
          id="accordion-3"
          accordion="my-accordion"
          v-model="collpaseOptions.cancelService"
          role="tabpanel"
          class="p-3"
        >
          <div class="cancel-options">
            <CancelService
              :serviceUuid="infoModal.registryUuid"
              :paymentType="infoModal.paymentType"
              :isModalActive="isModalOpened"
              @onSuccess="onSuccessCancellation"
            />
          </div>
        </b-tab>
    
        <!-- Assign / reasign order -->
        <b-tab
          v-if="
            !readonly && (STATUS_SERVICES_TO_ASSIGN.includes(infoModal.statusId) ||
              STATUS_SERVICES_TO_REASSIGN.includes(infoModal.statusId))
          "
          title="Asignar/Reasignar"
          id="accordion-4"
          accordion="my-accordion"
          v-model="collpaseOptions.assingService"
          role="tabpanel"
          class="p-3"
        >
          <div class="assign-service">
            <AssignReassignService
              :isModalActive="isModalOpened"
              :serviceUuid="infoModal.registryUuid"
              :addressUuid="addressUuid"
              :prevProviderUuid="prevProvider"
              :statusId="infoModal.statusId"
              :paymentTypeId="infoModal.paymentTypeId"
              :companyUuid="infoModal.companyUuid"
              @onSuccessAssignation="onSuccessAssignation"
              @onSuccessReassignation="onSuccessReassignation"
            />
          </div>
        </b-tab>
    
        <!-- Evidence -->
        <b-tab
          title="Evidencia"
          v-if="!readonly && (STATUS_SERVICES_TO_EVIDENCE.includes(infoModal.statusId) ||
            (!isCompanyAdmin && STATUS_SERVICES_TO_REASSIGN.includes(infoModal.statusId)))"
          id="accordion-5"
          accordion="my-accordion"
          role="tabpanel"
          class="p-3"
        >
          <ServiceEvidence
            :serviceUuid="infoModal.registryUuid"
            :onSuccessEvidenceUpdate="onSuccessEvidenceUpdate"
            :setError="setError"
            :setAlertError="setAlertError"
            :statusId="infoModal.statusId"
            :serviceEvidence="infoModal.evidence"
            :scrollTopError="scrollTopError"
            :formatToDatePicker="formatToDatePicker"/>
        </b-tab>
    
        <!-- Service review -->
        <b-tab
          title="Calificación"
          v-c-tooltip="
              'Muestra la calificación y comentario que realizó el cliente'
            "
          v-if="
            infoModal.statusId === STATUS_SERVICES.FINISHED.ID &&
              infoModal.review
          "
          id="accordion-6"
          accordion="my-accordion"
          role="tabpanel"
          class="p-3"
        >
          <div class="modal-text-wrapper">
            <div>
              <strong>Calificación: </strong
              ><span>{{ infoModal.review.score }}</span>
            </div>
            <div>
              <strong>Comentario: </strong
              ><span>{{ infoModal.review.comment || "" }}</span>
            </div>
          </div>
        </b-tab>
    
        <!-- Finish service(only general admin) -->
        <b-tab
          title="Finalizar"
          v-c-tooltip="
              'Sección para finalizar cualquier servicio creado o en proceso de entrega'
            "
          v-if="!readonly && STATUS_SERVICES_TO_FINISH_SERVICE.includes(infoModal.statusId)"
          id="accordion-8"
          accordion="my-accordion"
          role="tabpanel"
          class="p-3"
        >
          <div class="d-flex flex-column">
            <FinishService
              :isModalActive="isModalOpened"
              :statusId="infoModal.statusId"
              :serviceUuid="infoModal.registryUuid"
              :addressUuid="addressUuid"
              :paymentTypeId="infoModal.paymentTypeId"
              :hasEvidence="!!infoModal.evidence"
              :serviceTypeUuid="infoModal.ServiceTypeComplete ? infoModal.ServiceTypeComplete.registryUuid : null"
              @onSuccess="onSuccessFinishService"
            >
            </FinishService>
          </div>
        </b-tab>
    

        </b-tabs>

      </div>
      <template #footer>
        <div
          class="mr-auto"
          v-if="
            [STATUS_SERVICES.ACTIVE.ID, STATUS_SERVICES.CREATED.ID].includes(
              infoModal.statusId
            ) && !isCompanyAdmin
          "
        >
          <CButton
            v-c-tooltip="'Copiar mensaje de confirmación'"
            class="btn btn-dark"
            @click="copy"
          >
            <CIcon name="cil-copy" />
          </CButton>
          <p class="small d-inline ml-2" v-if="textCopied !== null">
            <CIcon
              :name="textCopied ? 'cil-check' : 'cil-x'"
              :class="textCopied ? 'text-success' : 'text-danger'"
            />
            {{ textCopied ? "Mensaje copiado" : "Error al copiar mensaje" }}
          </p>
          <a
            v-c-tooltip="'Enviar mensaje de confirmación'"
            class="btn btn-success ml-2"
            target="_blank"
            :key="infoModal.folio"
            :href="whatsappURL"
          >
            <CIcon name="cib-whatsapp" />
          </a>
        </div>

        <button
          v-if="
            STATUS_SERVICES_FINISHED_SERVICE.includes(infoModal.statusId) &&
              !isCompanyAdmin && !readonly
          "
          class="btn btn-primary"
          @click="reActivate"
        >
          Reactivar
        </button>

        <button
          v-if="
            [STATUS_SERVICES.ACTIVE.ID, STATUS_SERVICES.CREATED.ID].includes(
              infoModal.statusId
            ) &&
              checkValidServiceDate &&
              isCompanyAdmin && !readonly
          "
          class="btn btn-primary"
          @click="unlinkService"
        >
          Desvincular
        </button>

        <div v-if="serviceTab == 0">
          <b-button
            v-if="isGeneralAdmin"
            :disabled="!specsService.comment || disabledToggle"
            v-show="!comments.length || comments.length && !showModify"
            @click="saveComment(infoModal.registryUuid)"
            variant="primary"
          >
            Guardar Especificaciones
          </b-button>
          <b-button
            v-if="isGeneralAdmin"
            :disabled="!specsService.comment"
            v-show="comments.length && showModify"
            @click="updateComment"
            variant="primary"
          >
            Modificar Especificación
          </b-button>
          <b-button
            v-if="isGeneralAdmin"
            :disabled="!specsService.comment"
            v-show="comments.length && showModify"
            @click="cancelUpdated"
            class="ml-1"
            variant="danger"
          >
            Cancelar
          </b-button>
        </div>

        <div v-if="serviceTab == 2">
          <button
            type="submit"
            form="updateAdress"
            class="ml-auto btn btn-primary"
            :disabled="readonly"
            v-c-tooltip="
              'Guarda los cambios realizados en los datos de la dirección'
            "
          >
            Actualizar
          </button>
        </div>

        <button class="btn btn-dark" @click="closeModal()">
          Salir
        </button>
      </template>
    </CModal>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import * as MESSAGES from '../../common/messages'
import Voximplant from '../voximplant/voximplant'
import { getErrorMessage, ERRORS_MESSAGES } from '../../common/errors'
import { copyToClipboard, downloadFile } from '../../utils'
import GoogleMapsMark from '../GoogleMapsMark'
import CancelService from './CancelService'
import PaymentType from './PaymentType'
import Promocodes from './Promocodes'
import AssignReassignService from './AssignReassignService'
import AssingServiceCompany from './AssingCompany'
import FinishService from './FinishService'
import ServiceEvidence from './Evidences'

import {
  STATUS_SERVICES,
  STATUS_SERVICES_TO_CANCEL_BY_ADMIN,
  STATUS_SERVICES_FROM_ONE_TO_FIVE,
  STATUS_SERVICES_TO_ASSIGN,
  STATUS_SERVICES_TO_REASSIGN,
  STATUS_SERVICES_TO_CHANGE_STATUS,
  STATUS_SERVICES_TO_EVIDENCE,
  ALL_STATUS_SERVICES,
  CANCELLED_STATUS,
  STATUS_SERVICES_TO_ASSIGN_COMPANY,
  STATUS_SERVICES_TO_ASSIGN_COMPANY_DISABLED,
  STATUS_SERVICES_FINISHED_SERVICE,
  SERVICE_TYPES,
  STATUS_SERVICES_TO_FINISH_SERVICE,
  PERMISSIONS,
  PAYMENT_TYPES,
  SERVICE_COMMENT_TYPES,
  PAYMENT_LINK_STATUS
} from '../../common/enums'

const daysOfWeekSpanish = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']

const editAdressInitialState = () => ({
  street: '',
  numExt: '',
  numInt: '',
  zipcode: '',
  suburb: '',
  municipality: '',
  state: '',
  type: '',
  reference: ''
})

const editAdressIntegerInitialState = () => ({
  latitude: null,
  longitude: null
})
const detailFields = [
  { key: 'option', label: 'Cantidad' },
  { key: 'serviceTypeCategory', label: 'Categoría' },
  { key: 'showcase', label: 'Presentación' },
  { key: 'unitPrice', label: 'Precio Unitario' }
]
const notesField = { key: 'notes', label: 'Notas' }

export default {
  name: 'ServiceDetail',
  components: {
    DateRangePicker,
    Voximplant,
    GoogleMapsMark,
    CancelService,
    PaymentType,
    Promocodes,
    AssignReassignService,
    AssingServiceCompany,
    FinishService,
    ServiceEvidence
  },
  data() {
    return {
      isModalOpened: false,
      serviceTab: 0,
      infoModal: { registryUuid: '' },
      status: '',
      statusArray: [
        STATUS_SERVICES.ACTIVE.ID,
        STATUS_SERVICES.ACCEPTED.ID,
        STATUS_SERVICES.ARRIVED.ID,
        STATUS_SERVICES.FINISHED.ID,
        STATUS_SERVICES.ASSIGNED.ID,
        STATUS_SERVICES.TAKEN.ID
      ],
      textCopied: null,
      error: {},
      detailFields,
      notesField,
      companies: [],
      alertError: false,
      collpaseOptions: {
        details: false,
        editAddress: false,
        cancelService: false,
        assingService: false,
        assingServiceCompany: false
      },
      daysOfWeekSpanish,
      readonly: false,
      editDate: {
        startDate: '',
        endDate: ''
      },
      newAmount: 0,
      isCreatingLink: false,
      isUpdatingPaymentLinkStatus: false,
      updateableStatus: [1],
      loader: false,
      specsService: {
        comment: '',
        serviceUuid: ''
      },
      isActive: false,
      comments: [],
      trackingLink: null,
      success: {
        hasSuccess: false,
        message: ''
      },
      disabledToggle: false,
      // isModalActive: true,
      editAdress: editAdressInitialState,
      editAdressInteger: editAdressIntegerInitialState,
      // enums
      PAYMENT_TYPES,
      STATUS_SERVICES,
      STATUS_SERVICES_TO_CANCEL_BY_ADMIN,
      STATUS_SERVICES_FROM_ONE_TO_FIVE,
      STATUS_SERVICES_TO_ASSIGN,
      STATUS_SERVICES_TO_REASSIGN,
      STATUS_SERVICES_TO_CHANGE_STATUS,
      STATUS_SERVICES_TO_EVIDENCE,
      STATUS_SERVICES_TO_ASSIGN_COMPANY,
      STATUS_SERVICES_TO_ASSIGN_COMPANY_DISABLED,
      STATUS_SERVICES_FINISHED_SERVICE,
      STATUS_SERVICES_TO_FINISH_SERVICE,
      SERVICE_COMMENT_TYPES,
    }
  },
  computed: {
    ...mapGetters('auth', ['isCompanyAdmin', 'userPermissions', 'permissions', 'isGeneralAdmin', 'userHasCompany']),
    checkValidServiceDate() {
      const minutes = 30
      const serviceDate = moment(this.infoModal.serviceDate)
        .subtract(minutes, 'minutes')
        .utc()
      const currentDate = moment().utc(true)
      return serviceDate > currentDate
    },
    whatsappURL() {
      const { phoneNumber } = this.infoModal
      const urlEncodedText = window.encodeURIComponent(this.whatsappCopy())
      return `https://wa.me/52${phoneNumber}?text=${urlEncodedText}`
    },
  },
  watch: {
    registryUuid(value) {
      if (value) {
        this.loadServiceData()
      }
    },
    isModalActive(value) {
      this.isModalOpened = value
      if (value) {
        this.loadServiceData()
      }
    },
    infoModal(value) {
      switch (value.status) {
      case 'Creado':
        this.status = 'primary'
        break
      case 'Validado':
        this.status = 'info'
        break
      case 'Aceptado':
        this.status = 'info'
        break
      case 'En camino':
        this.status = 'info'
        break
      case 'Llegó':
        this.status = 'success'
        break
      case 'Asignado':
        this.status = 'success'
        break
      default:
        this.status = 'dark'
      }
    },
    isActive(value) {
      if (value) {
        this.updateStatus()
      }
    }
  },
  props: {
    registryUuid: {
      type: String,
      required: true
    },
    isModalActive: {
      type: Boolean,
      required: true
    }
  },
  mounted() {
    document.querySelector('.close').addEventListener('click', () => {
      this.closeModal()
    })
  },
  methods: {
    async loadServiceData() {
        try {
            this.serviceTab = 0
            this.loader = true
            this.success.hasSuccess = false
            this.success.message = ''
            this.error = {}
            this.isActive = false
            this.alertError = false
            this.editByAddress = true
            Object.assign(this.$data, editAdressInitialState())
            this.getSchedule()
            const payment = await this.getServicePayment(this.registryUuid)

            // getting info from endpoint
            this.axios.get(`/services/${this.registryUuid}`).then(async (response) => {
                const { data } = response
                this.infoModal = {
                ...data,
                payment
                }

                if (this.infoModal.categories.some(({ notes }) => !!notes)
                && !detailFields.some(({ key }) => key === notesField.key)) {
                detailFields.push(notesField)
                } else if (!this.infoModal.categories.some(({ notes }) => !!notes)) {
                const fieldIndex = detailFields.findIndex(({ key }) => key === notesField.key)
                if (fieldIndex >= 0) {
                    detailFields.splice(fieldIndex, 1)
                }
                }
                this.editDate.startDate = (
                data.deliveryDate ? data.deliveryDate : data.serviceDate
                ).replace('Z', '')
                this.editDate.endDate = (
                data.deliveryDate ? data.deliveryDate : data.serviceDate
                ).replace('Z', '')

                if (this.isCompanyAdmin) {
                this.updateableStatus = this.userPermissions
                    ? this.userPermissions.map(
                    (permission) => +permission.permission_action.oldValue
                    )
                    : []
                }

                if (this.isCompanyAdmin) {
                this.updateableStatus = this.userPermissions
                    ? this.userPermissions.map(
                    (permission) => +permission.permission_action.oldValue
                    )
                    : []
                }

                if (data.addressUuid) {
                this.addressUuid = data.addressUuid
                }
                if (data.statusId === 8 && !data.cancellation) {
                data.cancellation = { isFree: true }
                }

                if (data.provider) {
                  this.nearbyProviderSelected = data.provider.registryUuid
                  this.prevProvider = data.provider.registryUuid
                } else {
                  this.nearbyProviderSelected = null
                  this.prevProvider = ''
                }

                const { registryUuid: serviceTypeUuid } = data.ServiceTypeComplete || {}
                if (data.address) {
                this.editAdress = { ...data.address }
                this.editAdressInteger = { ...data.address }
                const { latitude, longitude } = data.address
                await this.fetchCompanies({ latitude, longitude, serviceTypeUuid })
                } else {
                await this.fetchCompanies({ serviceTypeUuid })
                }

                this.newAmount = data.amount

                this.collpaseOptions.details = true
                // settings for map
                if (this.STATUS_SERVICES_TO_CANCEL_BY_ADMIN.includes(data.statusId)) {
                this.$nextTick(() => {
                    const { latitude, longitude } = data.address
                    if (latitude && longitude) {
                    this.loadMapCoordenates()
                    } else {
                    this.loadMapAddress()
                    }
                })
                }

                if (this.infoModal.payment && this.infoModal.payment.paymentLinkId) {
                  await this.getPaymentLinkStatus(this.infoModal.payment.paymentLinkId)
                }

                await this.fetchTrackingLink(data.registryUuid)
            })

            this.getComments(this.registryUuid).finally(() => this.loader = false)
            this.showModify = false
            this.specsService.comment = ''
        } catch (ex) {
            console.log(ex)
        }
    },
    async unlinkService() {
      const { registryUuid } = this.infoModal
      try {
        await this.axios.patch(
          `services/${registryUuid}/remove-assign-company`
        )
        this.onSuccessUnlinkService()
      } catch (err) {
        this.alertError = true
        this.error = getErrorMessage(err)
        this.scrollTopError()
      }
    },
    async getSchedule() {
      try {
        const { data } = await this.axios.get('/schedules/available')
        this.schedules = data
      } catch (error) {
        this.schedules = null
      }
    },
    async getServicePayment(uuid) {
      try {
        const { data } = await this.$axios.get(`/payments/${uuid}`)
        return data
      } catch (error) {
        this.isErrorResponse = true
      }
    },
    loadMapAddress() {
      const {
        street, numExt, numInt, suburb, municipality, state
      } = this.editAdress
      const address = `${street} ${numExt}${
        numInt ? ` ${numInt}` : ''
      }, ${suburb}, ${municipality}, ${state}`
      if (this.$refs && this.$refs.map) {
        this.$refs.map.setMarkFromAddress(address)
      }
    },
    async getComments(serviceUuid) {
      const type = `types=${this.SERVICE_COMMENT_TYPES.ADMIN_SPECIFICATION}`
      try {
        this.error = {}
        const { data } = await this.$serverless.get(`services/comment/${serviceUuid}?${type}`)
        this.comments = data
      } catch (error) {
        this.error = getErrorMessage(error)
        this.loader = false
        this.disabledToggle = false
      }
    },
    async fetchCompanies({ latitude = null,longitude = null,serviceTypeUuid = null }) {
      // Cache
      try {
        let path = 'providers/companies?format=asgmt'
        if (latitude && longitude) {
          path = `providers/companies/reassign?latitude=${latitude}&longitude=${longitude}&serviceTypeUuid=${serviceTypeUuid}`
        }
        const { data } = await this.axios.get(path)

        this.companies = data.map((company) => {
          company.name = company.isCovered
            ? `${company.name} - SI`
            : `${company.name} - NO`
          return company
        })
      } catch (err) {
        this.alertError = true
        this.error = getErrorMessage(err)
      }
    },
    async fetchTrackingLink(serviceUuid) {
      try {
        const { data } = await this.$serverless.post('/checkout/tracking/link', {
          serviceUuid
        })
        this.trackingLink = data.trackingLink
      } catch (error) {
        this.isErrorResponse = true
      }
    },
    closeModal(refresh){
      this.$emit('onClose', refresh)
    },
    async saveComment(uuid) {
      try {
        this.error = {}
        this.loader = true
        this.disabledToggle = true
        await this.$serverless.post('services/comment', {
          comment: this.specsService.comment,
          serviceUuid: uuid,
          type: this.SERVICE_COMMENT_TYPES.ADMIN_SPECIFICATION
        })
        this.specsService.comment = ''
        this.getComments(uuid).finally(() => {
          this.loader = false
          this.disabledToggle = false
        })
      } catch (error) {
        this.error = getErrorMessage(error)
        this.loader = false
        this.disabledToggle = false
      }
    },
    async createPaymentLink() {
      try {
        if(!this.infoModal.registryUuid) {
          return
        }

        this.isCreatingLink = true
        const payment = await this.createPaymentLinkPost(this.infoModal.registryUuid)
        this.infoModal.payment = payment
      } catch (error) {
        console.error(error)
      } finally {
        this.isCreatingLink = false
      }
    },
    async createPaymentLinkPost(serviceUuid) {
      try {
        if(!serviceUuid) {
          return
        }

        const { data } = await this.$serverless.post('/payment/liquido/paymentlink/create', {
          serviceUuid
        })
        await this.getPaymentLinkStatus(data.paymentLinkId)

        return data
      } catch (error) {
        this.isErrorResponse = true
      }
    },
    async getPaymentLinkStatus(paymentLinkId) {
      try {
        if(!paymentLinkId) {
          return
        }

        this.isUpdatingPaymentLinkStatus = true

        const { data } = await this.$serverless.get(`/payment/liquido/paymentlink/${paymentLinkId}`)
        const status = data.paymentStatus
        this.infoModal.payment.paymentLinkStatus = PAYMENT_LINK_STATUS[+status]
        this.isUpdatingPaymentLinkStatus = false
      } catch (error) {
        this.isErrorResponse = true
      }
    },
    async reActivate() {
      const { registryUuid } = this.infoModal
      try {
        const { data } = await this.axios.put(
          `services/${registryUuid}/activate`
        )
        const { paidByCard } = data
        if (paidByCard) {
          this.topAlertError = true
          this.error = ERRORS_MESSAGES.RETURN_MONEY
        }
        if (moment(this.infoModal.serviceDate) <= moment()) {
          this.topAlertError2 = true
          this.error2 = ERRORS_MESSAGES.CHANGE_DATE
        }
        this.onSuccessReactivateService()
      } catch (err) {
        this.alertError = true
        this.error = getErrorMessage(err)
        this.scrollTopError()
      }
    },
    seeCustomerDetail() {
      this.$router.push({
        name: 'Customers',
        params: {
          hideSidebar: true,
          registryUuid: this.infoModal.customerUuid,
          phoneNumber: this.infoModal.phoneNumber
        }
      })
    },
    editAddressAction() {
      const edits = {}
      const vm = this
      Object.keys(this.editAdress).forEach((key) => {
        if (vm.editAdress[key]) {
          edits[key] = vm.editAdress[key]
        }
      })
      this.axios
        .put(`/customers/address/${this.addressUuid}`, {
          ...edits,
          latitude: parseFloat(this.editAdressInteger.latitude),
          longitude: parseFloat(this.editAdressInteger.longitude),
          serviceUuid: this.infoModal.registryUuid
        })
        .then(() => {
          this.onSuccessAddressUpdate()
          this.alertError = false
        })
        .catch((err) => {
          this.alertError = true
          this.scrollTopError()
          this.error = getErrorMessage(err)
        })
    },
    setDisableDistanceRestriction() {
      const serviceUuid = this.infoModal.registryUuid
      this.$axios
        .put('/services/distance_restriction', {
          serviceUuid,
          disableDistanceRestriction: !this.infoModal.disableDistanceRestriction
        })
        .then((response) => {
          this.onSuccessDisableDistanceRestriction()
          this.alertError = false
        })
        .catch((err) => {
          this.alertError = true
          this.scrollTopError()
          this.error = getErrorMessage(err)
        })
    },
    updateStatus() {
      const serviceUuid = this.infoModal.registryUuid
      this.axios
        .put(`/services/${serviceUuid}/status`, {
          statusId: this.getNextStatus()
        })
        .then((response) => {
          const {
            data: { assigned, provider }
          } = response
          this.onSuccessStatusUpdate(assigned, provider)
          this.alertError = false
        })
        .catch((err) => {
          this.alertError = true
          this.scrollTopError()
          this.error = getErrorMessage(err)
        })
    },
    getNextStatus() {
      switch (this.infoModal.statusId) {
      case this.STATUS_SERVICES.CANCEL_BY_ADMIN_COST.ID:
      case this.STATUS_SERVICES.ASSIGNED.ID:
        return this.STATUS_SERVICES.ACCEPTED.ID
      case this.STATUS_SERVICES.ACCEPTED.ID:
      case this.STATUS_SERVICES.EN_CAMINO.ID:
        return this.STATUS_SERVICES.ARRIVED.ID
      default:
        return this.STATUS_SERVICES_TO_ASSIGN[0]
      }
    },
    updatePriority() {
      const serviceUuid = this.infoModal.registryUuid
      this.axios
        .put(`/services/${serviceUuid}/priority`, {
          priority: !this.infoModal.priorityFlag
        })
        .then((response) => {
          const { data } = response
          this.infoModal.priorityFlag = data.priorityFlag
          this.onSuccessPriorityUpdate()
          this.alertError = false
        })
        .catch((err) => {
          this.alertError = true
          this.scrollTopError()
          this.error = getErrorMessage(err)
        })
    },
    removeDecimals(value) {
      return value ? value / 100 : value
    },
    formatProviders(name) {
      return name === null ? 'Sin nombre' : name
    },
    formatScheduleDay(value) {
      if (!value) {
        return 'No disponible'
      }
      
      if (typeof value === 'string') value = JSON.parse(value) 

      const startTimeMeridianFormat = value.max === '12' ? 'am' : value.meridian
      const startTimeString = `${value.min} ${startTimeMeridianFormat}`
      const endTimeString = `${value.max} ${value.meridian}`

      return `${startTimeString} - ${endTimeString}`
    },
    disabledDates(classes, date) {
      const dd = new Date().getDate()
      const mm = new Date().getMonth() + 1
      const yyyy = new Date().getFullYear()
      const today = `${yyyy}-${mm}-${dd}`
      const getCalendarDate = moment(date.getTime()).format()

      if (!classes.disabled) {
        classes.disabled = getCalendarDate < moment(today).format()
      }
      return classes
    },
    updateDate() {
      const serviceUuid = this.infoModal.registryUuid
      const date = this.editDate.startDate
        .toString()
        .replace(/[GMT0-9-]{8}/g, '')
      const formattedDate = moment.utc(date).format()
      this.axios
        .put(`/services/${serviceUuid}/deliveryDate`, {
          deliveryDate: formattedDate
        })
        .then(() => {
          this.onSuccessDateUpdate()
          this.alertError = false
        })
        .catch((err) => {
          this.alertError = true
          this.scrollTopError()
          this.error = getErrorMessage(err)
        })
    },
    showCreatePaymentLinkButton() {
      return (
        this.infoModal
        && !this.isCompanyAdmin
        && (!this.infoModal.payment || !this.infoModal.payment.paymentLinkUrl)
        && [
          this.STATUS_SERVICES.FINISHED.ID,
          this.STATUS_SERVICES.CANCEL_BY_CLIENT.ID,
          this.STATUS_SERVICES.CANCEL_BY_PROVIDER.ID,
          this.STATUS_SERVICES.CANCEL_BY_ADMIN_COST.ID
        ].includes(this.infoModal.statusId) === false
      )
    },
    async cancelUpdated() {
      this.showModify = false
      this.specsService.comment = ''
    },
    async toggleUpdateComment(comment, commentUuid, registryUuid) {
      this.commentUuid = commentUuid
      this.specsService.comment = comment
      this.showModify = true
      this.registryUuidModify = registryUuid
    },
    getAddressContact() {
      return this.infoModal.address
        && this.infoModal.address.contacts
        && this.infoModal.address.contacts.length > 0
        ? this.infoModal.address.contacts[0].name
        : this.infoModal.name
    },
    async updateComment() {
      try {
        this.loader = true
        this.error = {}
        this.showModify = false
        this.disabledToggle = true
        await this.$serverless.put('services/comment', {
          commentUuid: this.commentUuid,
          comment: this.specsService.comment
        })
        this.specsService.comment = ''
        this.getComments(this.registryUuidModify).finally(() => {
          this.loader = false
          this.disabledToggle = false
        })
      } catch (error) {
        this.error = getErrorMessage(error)
        this.disabledToggle = false
        this.loader = false
      }
    },
    async saveMapCoordenates(mark) {
      const { lat, lng } = mark || this.$refs.map.mark
      this.editAdress.latitude = lat
      this.editAdress.longitude = lng
      const latlng = {
        lat,
        lng
      }
      if (!this.editByAddress) {
        const address = await this.$refs.map.getAdressFromPoint(latlng)
        if (!address) {
          return null
        }
        this.editAdress.street = address[1].long_name
        this.editAdress.numExt = address[0].long_name
        this.editAdress.zipcode = address[6].long_name
        this.editAdress.suburb = address[2].long_name
        this.editAdress.municipality = address[3].long_name
        this.editAdress.state = address[4].long_name
      }
      return this.editAdress
    },
    getSwitchLabel(statusId, isChecked) {
      switch (statusId) {
      case this.STATUS_SERVICES.ACCEPTED.ID:
        return isChecked ? 'Llegó' : 'En Camino'
      case this.STATUS_SERVICES.ASSIGNED.ID:
        return isChecked ? 'En camino' : 'Asignado'
      default:
        return isChecked ? 'Validado' : 'Creado'
      }
    },
    loadMapCoordenates() {
      const { latitude, longitude } = this.editAdress

      const mark = {
        lat: +latitude,
        lng: +longitude
      }
      if (this.$refs.map) {
        this.$refs.map.setMark(mark)
      }
    },
    updateMapByChangingAddress() {
      if (this.editByAddress) {
        this.loadMapAddress()
      }
    },
    getGoogleMapsURL() {
      const { latitude, longitude } = this.infoModal.address
      return latitude && longitude
        ? `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
        : `https://www.google.com/maps/search/?api=1&query=${this.infoModal.address.replace(
          /, /g,
          '+'
        )}`
    },
    goToGoogleMaps() {
      const url = this.getGoogleMapsURL()
      window.open(url, '_blank')
    },
    updateAmount() {
      const serviceUuid = this.infoModal.registryUuid
      this.axios
        .put(`services/${serviceUuid}/amount`, {
          amount: this.newAmount
        })
        .then(() => {
          this.onSuccessAmountUpdate()
          this.alertError = false
        })
        .catch((err) => {
          this.alertError = true
          this.scrollTopError()
          this.error = getErrorMessage(err)
        })
    },
    // Utils
    onlyNumbers(e) {
      const key = String.fromCharCode(e.charCode)
      if (!/^[0-9]$/.test(key)) {
        e.preventDefault()
      }
    },
    alphaNumeric(e) {
      const key = String.fromCharCode(e.charCode)
      if (!/^[A-Za-zÑñÁÉÍÓÚÜáéíóúü0-9 ]$/.test(key)) {
        e.preventDefault()
      }
    },
    onlyLetters(e) {
      const key = String.fromCharCode(e.charCode)
      if (!/^[A-Za-zÑñÁÉÍÓÚÜáéíóúü. ]$/.test(key)) {
        e.preventDefault()
      }
    },
    copy() {
      this.textCopied = copyToClipboard(this.whatsappCopy())
      setTimeout(() => {
        this.textCopied = null
      }, 1000)
    },
    // TODO Move this to new React View @Angelo, @Luis
    whatsappCopy() {
      const template = MESSAGES.WHATSAPP
      const serviceDate = moment.utc(this.infoModal.deliveryDate ? this.infoModal.deliveryDate : this.infoModal.serviceDate)
      const date = serviceDate.format('DD / MM / YYYY').toString()
      const time = serviceDate.format('HH:mm').toString()
      const address = this.formatAddress(this.infoModal.address)
        .replace(/\n/g, '')
        .replace(/[ ]{2,}/g, ' ')
        .replace(/, , /g, ', ')

      const { latitude, longitude } = this.infoModal.address

      const googleMapsURL = latitude && longitude
        ? `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
        : `https://www.google.com/maps/search/?api=1&query=${address.replace(
          /, /g,
          '+'
        )}`

      const {
        name, paymentType, discount, amount, totalAmount, folio
      } = this.infoModal

      const text = template
        .replace(/{name}/g, name || '') // Default Value
        .replace(/{folio}/g, folio) // Default Value
        .replace(/{date}/g, date)
        .replace(/{time}/g, time)
        .replace(/{amount}/g, amount)
        .replace(/{discount}/g, discount)
        .replace(/{totalAmount}/g, totalAmount)
        .replace(/{paymentType}/g, paymentType)
        .replace(/{address}/g, address)
        .replace(/{googleMapsURL}/g, googleMapsURL)
      return text
    },
    async updateServiceChargeback() {
      const { registryUuid: serviceUuid } = this.infoModal
      try {
        await this.axios.put(`services/${serviceUuid}/chargeback`)
        this.infoModal.chargeback = !this.infoModal.chargeback
      } catch (err) {
        this.alertError = true
        this.scrollTopError()
        this.error = getErrorMessage(err)
      }
    },
    // Formats
    formatAddress(data) {
      let string = ''
      if (data) {
        const numInt = data.numInt ? data.numInt : ''
        string = `${data.street}, ${data.numExt}, ${numInt}, ${data.zipcode}, 
                ${data.suburb}, ${data.municipality}, ${data.state}`
      }
      return string
    },
    formatPaymentType(value) {
      return value === 'Tarjeta (TDEB | TCR)' ? 'Pasarela de pago' : value
    },
    formatMoney(value) {
      const val = (value / 1).toFixed(2).replace(',', '.')
      if (value) {
        return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
    formatToDatePicker(date) {
      return moment(date)
        .format('DD/MM/YYYY hh:mm A')
    },
    getStatusTooltip(statusId) {
      switch (statusId) {
      case STATUS_SERVICES.ASSIGNED.ID:
        return 'Actualiza el estatus del servicio de Asignado a En Camino'
      case this.STATUS_SERVICES.ACCEPTED.ID:
        return 'Actualiza el estatus del servicio de En Camino a Llegó'
      default:
        return 'Actualiza el estatus del servicio de Creado a Validado'
      }
    },
    scrollTopError() {
      this.$nextTick(() => {
        this.$refs.ServicesAlertError.focus()
      })
    },
    scrollTopSuccess() {
      this.$nextTick(() => {
        this.$refs.successServices.focus()
      })
    },
    onSuccessPriorityUpdate() {
      this.defaultSuccessBehavior('Prioridad actualizada con éxito.')
      return false
    },
    // Succes
    onSuccessDateUpdate() {
      this.defaultSuccessBehavior('Fecha y hora actualizada con éxito.')
    },
    defaultSuccessBehavior(message) {
      this.closeModal(true)
      this.scrollTopSuccess()
      this.setSuccessMessage(message)
    },
    setSuccessMessage(message) {
      this.success.hasSuccess = true
      this.success.message = message
    },
    onPaymentSuccess() {
      this.defaultSuccessBehavior(
        'El método de pago se actualizó correctamente'
      )
    },
    onPromocodeSuccess() {
      this.defaultSuccessBehavior(
        'El código promocinal se actualizó correctamente'
      )
    },
    onSuccessCancellation() {
      this.defaultSuccessBehavior('Servicio cancelado con éxito.')
    },
    onSuccessAmountUpdate() {
      this.defaultSuccessBehavior('Monto actualizado con éxito.')
    },
    onSuccessEvidenceUpdate() {
      this.defaultSuccessBehavior('Evidencia actualizada con éxito.')
    },
    onSuccessFinishService() {
      this.defaultSuccessBehavior('Orden de servicio finalizada con éxito.')
    },
    onSuccessReactivateService() {
      this.defaultSuccessBehavior('Se ha reactivado el servicio.')
    },
    onSuccessUnlinkService() {
      this.defaultSuccessBehavior('Servicio desvinculado con éxito.')
    },
    onSuccessCompanyAssignation() {
      this.defaultSuccessBehavior('Servicio asignado con éxito.')
    },
    onSuccessCancellation() {
      this.defaultSuccessBehavior('Servicio cancelado con éxito.')
    },
    onSuccessAssignation() {
      this.defaultSuccessBehavior('Servicio asignado con éxito.')
    },
    onSuccessReassignation() {
      this.defaultSuccessBehavior('Servicio reasignado con éxito.')
    },
    onSuccessAddressUpdate() {
      this.defaultSuccessBehavior('Domicilio actualizado con éxito.')
    },
    onSuccessDateUpdate() {
      this.defaultSuccessBehavior('Fecha y hora actualizada con éxito.')
    },
    onSuccessReactivateService() {
      this.defaultSuccessBehavior('Se ha reactivado el servicio.')
    },
    servicesUpdatedMessage() {
      this.defaultNewServiceAlertBehavior('Hay nuevos servicios creados')
    },
    onSuccessStatusUpdate(assigned, provider = null) {
      if (assigned) {
        this.defaultSuccessBehavior(
          `Estatus actualizado con éxito y fue asignado a ${provider}.`
        )
        return false
      }
      this.defaultSuccessBehavior('Estatus actualizado con éxito.')
      return false
    },
    onSuccessDisableDistanceRestriction() {
      this.defaultSuccessBehavior('Restricción de Distancia actualizada con éxito.')
      return false
    },
    // Errors
    setError(error) {
      this.error = error
    },
    setAlertError(alertError) {
      this.alertError = alertError
    },
    onErrorPayment(err) {
      this.alertError = true
      this.scrollTopError()
      this.error = getErrorMessage(err)
    },
    onErrorPromocodes(err) {
      this.alertError = true
      this.scrollTopError()
      this.error = getErrorMessage(err)
    },
  }
}
</script>

<style lang="scss" scoped>
.menu {
  background-color: #DED9F9;
  border: 0px;
  color: black;
}

.title {
  color: black;
  background-color: red;
}

.resume-card{
 background-color: #fff;
 padding: 0;
 margin: 0;
 > h2{
  margin: 0;
 }
}
.accordeon-icon{
  display: flex;
  align-items: center;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s
}
.accordeon-icon::after{
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out;

}
.accordeon-icon:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}

.ml-10{
  margin-left: 20%;
}
.cursor-pointer{
  cursor: pointer;
}
.cursor-pointer:hover{
  background-color: #c7c5c5;
}
.services {

  .add-service-button {
    position: fixed;
    bottom: 4%;
    right: 44px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    z-index: 1031;
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 5px;
  }
  .alerts:focus {
    outline: none;
  }
  .evidence-img {
    max-height: 400px;
  }
  .img-card,
  .card-add-evidence {
    padding-top: 8px;
    margin-bottom: 8px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    .figure-caption {
      background-color: #2a1ab9;
      color: #fff;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      font-size: 14px;
      padding: 4px 0;
    }
    > .date-picker {
      margin-top: 8px;
      padding: 4px;
      background-color: #2a1ab9;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  .modal-text-wrapper {
    > div {
      margin-bottom: 8px;
    }
    .amount-requested {
      display: flex;
      > div {
        margin-left: 4px;
        input {
          width: 30%;
          border: 1px solid #ccc;
        }
        button {
          border: 1px solid #5b2ae0;
        }
      }
    }
  }
  .card {
    border: none;
    margin-bottom: 0;
    .card-header,
    .card-footer {
      display: flex;
      p {
        margin: auto 0 auto auto;
      }
    }
    .card-body {
      padding: 12px;
      margin-bottom: 0;
      .filters-section {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        .second-fiters {
          display: flex;
          flex-direction: column;
        }
        .open-filter {
          > div {
            &:first-child {
              display: flex;
              margin-bottom: 16px;
            }
            &:last-child {
              width: 256px;
            }
          }
          .open-filter-input {
            height: 35px;
            box-sizing: content-box;
            padding: 0 16px;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 15px;
            width: calc(100% - 165px);
          }
          button {
            height: 35px;
            border-radius: 4px;
            margin-left: 4px;
          }
        }
        .status-filter {
          margin: 8px auto 0 auto;
          div {
            padding: 0 16px;
            input {
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
      .sorter-button {
        background-color: #fff;
        border: 1px solid #ccc;
        margin-left: 7px;
        outline: none;
      }
    }
  }
  .edit-address {
    form {
      > div {
        display: flex;
        margin-bottom: 8px;
        &.latitude-longitude {
          button {
            width: 25px;
            height: 25px;
            border: 0;
            border-radius: 4px;
            margin-left: 4px;
            background-color: #3c4b64;
            color: #fff;
          }
        }
        > div {
          display: flex;
          flex-direction: column;
          width: 50%;
          &:first-child {
            margin-right: 4px;
          }
        }
        input {
          height: 35px;
          box-sizing: border-box;
          padding: 0 16px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
      }
    }
    .edit-address-buttons {
      display: flex;
      .tabs-selector {
        border: 1px solid #ccc;
        display: flex;
        height: 35px;
        border-radius: 4px;
        width: 150px;
        button {
          border: none;
          background-color: #fff;
          outline: none;
          color: #000015;
          width: calc(100% / 2);
          border-right: 1px solid #ccc;
          border-radius: 4px;
          &.active {
            background-color: #5b2ae0;
            color: white;
          }
          &:last-child {
            border-right: 0;
          }
        }
      }
    }
  }
  .services-overview {
    > .card {
      padding: 16px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-evenly;
      > div {
        padding: 8px;
        margin: 8px 0;
        width: 150px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
          0 2px 10px 0 rgba(0, 0, 0, 0.12);
        text-align: center;
        &:first-child {
          margin-left: 0;
        }
        p {
          font-size: 16px;
          line-height: normal;
          margin-bottom: 8px;
        }
        span {
          font-size: 24px;
          font-weight: bold;
        }
      }
    }
  }
  .card-comments {
    width: 18rem;
    height: 9rem;
  }

  .card-error {
    overflow: none;
    padding: 0;
  }

  .card-error .card-body {
    padding: 0px !important;
  }
  .button-delete-comment, .button-update-comment {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button-delete-comment {
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
  }
  .button-update-comment {
    right: 48px;
    top: 10px;
    width: 64px;
    height: 21px;
  }

  .center-flag {
    text-align: center;
    color: red;
    padding: 22px;
  }
  .red-color {
    color: red
  }
  .center-image {
    display: flex;
    margin-top: 10px;
  }
  .detail-modal {
    .assign-company-wrapper {
      display: flex;
    }
  }
  .time-left-grey {
    background-color: #B0B8C4;
    color: white;
  }
  .time-left-red {
    background-color: #ec225e;
    color: white;
  }
  .time-left-yellow {
    background-color: #ff8300;
    color: white;
  }
  .time-left-green {
    background-color: #72e872;
  }
  .tabs-selector {
    border: 1px solid #ccc;
    display: flex;
    height: 35px;
    border-radius: 4px;
    button {
      border: none;
      background-color: #fff;
      outline: none;
      color: #000015;
      width: calc(100% / 2);
      border-right: 1px solid #ccc;
      border-radius: 4px;
      &.active {
        background-color: #5b2ae0;
        color: white;
      }
      &:last-child {
        border-right: 0;
      }
    }
  }
  .tf-complements {
    display: block;
    color: #9f9faa;
    font-size: .78rem;
    margin-top: 5px;
  }
  @media (min-width: 992px) {
    .card {
      .card-comments .card-body {
        overflow: auto;
        padding: 0rem;
      }
      .card-body {
        padding: 1.25rem;
        .filters-section {
          flex-direction: row;
          .second-fiters {
            margin: auto 0 auto auto;
            min-width: 330px;
            > div {
              &:first-child {
                margin-bottom: 16px;
              }
              &:last-child {
                display: flex;
              }
            }
          }
          .date-range {
            margin: auto 8px auto 0;
          }
          .status-filter {
            margin: 0;
          }
          .open-filter {
            margin: auto 10px auto 0;
            .open-filter-input {
              font-size: 12px;
              width: 27%;
            }
          }
        }
      }
    }
  }

  @media (max-width: 360px) {
    .tabs-selector {
      font-size: 10px;
    }
  }
}

  .link {
    cursor: pointer;
    color: #321fdb;
  }
  .link:hover {
    text-decoration: underline;
  }
</style>