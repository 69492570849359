<template>
  <div>
    <div class="form-group autocomplete-container">
        <CIcon name="cilLocationPin" class="inline-container"></CIcon>
        <div class="inline-container"> 
          <gmap-autocomplete
            @place_changed="setPlace"
            placeholder="Escribe aquí tu dirección"
            class="form-group full-width input"
            spellcheck="false"
            autocomplete="off"
            aria-autocomplete="none"
          >
          </gmap-autocomplete>
        </div>
    </div>
    <GmapMap
      :center="coordenadasCenterMap"
      :zoom="16"
      map-type-id="terrain"
      style="width: 100%; height: 297px"
      ref="mapRef"
      :options="{
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
      }"
    >
      <GmapMarker
        :position.sync="formOkBoy.coordenadasMap"
        :clickable="true"
        :draggable="true"
        @dragend="updateMaker"
      ></GmapMarker>
    </GmapMap>
    <small>
      <p>
        {{
          displayValidText(formOkBoy.address.street) +
          " " +
          displayValidText(formOkBoy.address.numExt) +
          " " +
          displayValidText(formOkBoy.address.suburb) +
          " " +
          displayValidText(formOkBoy.address.municipality) +
          " " +
          displayValidText(formOkBoy.address.state) +
          " " +
          displayValidText(formOkBoy.address.zipcode)
        }}
      </p>
    </small>
  </div>
</template>

<script>
import Axios from "axios";
import { mapFields } from "vuex-map-fields";

export default {
  computed: {
    ...mapFields([
      "formOkBoy",
      "coverageFlag",
      "coordenadasCenterMap",
      "coordenadasMarker",
      "showMessage",
      "message"
    ]),
  },
  watch: {
    'formOkBoy.coordenadasMap': function() {
      this.setMarker({lat: +this.formOkBoy.address.latitude, lng: +this.formOkBoy.address.longitude})
      this.fillSearchInput();
    }
  },
  data: () => ({
    autocompletedata: null
  }),
  methods: {
    displayValidText(value) {
      return value === 'NA' ? '' : value
    },
    setPlace(place) {
      this.coverageFlag = false;
      this.currentPlace = place;
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.coordenadasMarker = marker;
        this.formOkBoy.coordenadasMap = marker;
        this.coordenadasCenterMap = marker;
      }
      this.searchAddess(place.address_components);
    },
    setMarker(marker) {
      if (!marker.lat || !marker.lng) return

      this.coordenadasMarker = marker;
      this.coordenadasCenterMap = marker;
    },
    updateMaker: function (event) {
      this.coverageFlag = false;
      let lat = event.latLng.lat();
      let lng = event.latLng.lng();
      this.formOkBoy.coordenadasMap = {
        lat: lat,
        lng: lng,
      };
      this.getAddress(lat, lng);
    },
    getAddress(lat, lng) {
      let api = Axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          lat +
          "," +
          lng +
          "&key=AIzaSyD3Lt1KM-kDNRBpmtScC-sLtMBFu6BpM-k"
      )
        .then((response) => {
          this.searchAddess(response.data.results[0].address_components);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    searchAddess(Address) {
      this.formOkBoy.address.longitude = this.formOkBoy.coordenadasMap.lng.toString();
      this.formOkBoy.address.latitude = this.formOkBoy.coordenadasMap.lat.toString();
      for (let i = 0; i < Address.length; i++) {
        for(let j = 0; j < Address[i].types.length; j++) {
          switch (Address[i].types[j]) {
          case "street_number":
            this.formOkBoy.address.numExt = Address[i].long_name;
            break;
          case "route":
            this.formOkBoy.address.street = Address[i].long_name;
            break;
          case "sublocality_level_1":
            this.formOkBoy.address.suburb = Address[i].long_name;
            break;
          case "locality":
            this.formOkBoy.address.municipality = Address[i].long_name;
            break;
          case "administrative_area_level_1":
            this.formOkBoy.address.state = Address[i].long_name;
            break;
          case "postal_code":
            this.formOkBoy.address.zipcode = Address[i].long_name;
            break;0
        }
        }
      }
      
      this.fillSearchInput();
      this.$emit('onAddressChange', this.formOkBoy)
    },
    fillSearchInput(){
      const element = document.getElementsByClassName('pac-target-input')[0]
      if (!element) {
        return null
      }
      const addressValue =
          this.formOkBoy.address.street +
          " " +
          this.formOkBoy.address.numExt +
          " " +
          this.formOkBoy.address.suburb +
          " " +
          this.formOkBoy.address.municipality +
          " " +
          this.formOkBoy.address.state +
          " " +
          this.formOkBoy.address.zipcode
      element.value = addressValue
    }
  },
};

window.dataLayer = window.dataLayer || [];
window.dataLayer.push({
    'event': 'Pageview',
    'pagePath': 'https://okboy.app/address',
    'pageTitle': 'Customer Address Info'
  });
</script>

<style scoped>
.autocomplete-container {
  height: 26px;
}
.pac-target-input {
  outline-color: #cdcdcd;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid;
  color: #768192;
  background-color: #fff;
  border-color: #d8dbe0;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);
}
.inline-container {
    display: inline-block;
    width: 97%;
}
.pac-container {
  z-index: 99999 !important;
}
.full-width {
  width:100%;
}
input:focus {
  outline: none;
}
</style>