<template>
    <CModal :closeOnBackdrop="false" :show.sync="isModalActive" size="xl">
        <template v-slot:header>
            <h5 class="modal-title">Suscripción</h5>
            <button type="button" :disabled="isRequesting" aria-label="Close" class="close"
                @click="onCancel($event, true)">
                ×
            </button>
        </template>
        <CAlert :color="'danger'" :closeButton="true" :show.sync="hasError">
            {{ error }}
        </CAlert>
        <form novalidate v-show="!configuringService" id="createRecurrence" ref="form" v-on:submit.prevent="onSubmit">
            <div class="div-50-perc">
                <h4>Datos del cliente</h4>
                <div class="form-group">
                    <label class="label-text">Teléfono</label>
                    <input class="form-control input-group" placeholder="Teléfono del cliente"
                        v-model="customerPhoneNumber" type="tel" pattern="[0-9]{10}" maxlength="10"
                        @paste="onPaste($event)" required v-on:keydown.enter.prevent
                        :disabled="infoModal && infoModal.customerPhoneNumber" @keyup="fetchCustomerServices" />
                </div>
                <div v-show="!hasConfiguredService">
                    <CDataTable :items="customerServices" :fields="fields" :noItemsView="noItemsView" hover
                        :sorter="{ external: true }" :sorterValue.sync="sort" @row-clicked="onRowClicked"
                        class="main-table">
                        <!-- custom table fields -->
                        <template #folio="{ item }">
                            <td>{{ item.folio }}</td>
                        </template>
                        <template #serviceCategory="{ item }">
                            <td>{{ item.serviceCategory }}</td>
                        </template>
                        <template #units="{ item }">
                            <td>{{ getUnits(item) }}</td>
                        </template>
                        <template #serviceDate="{ item }">
                            <td>{{ formatDateTime(item.serviceDate) }}</td>
                        </template>
                    </CDataTable>
                </div>
                <div v-show="hasConfiguredService">
                    <h4>Servicio Configurado</h4>
                    <div class="service-control-buttons">
                        <button type="button" class="btn btn-secondary mr-1" @click="selectNewService()"
                            v-if="!infoModal || !infoModal.registryUuid">Seleccionar nuevo</button>
                        <button type="button" class="btn btn-success ml-1" @click="editService()"
                            v-c-tooltip="'Modificar servicio recurrente'"
                            v-if="!disableInput(configuredService.deviceFrom)">Modificar</button>
                    </div>
                    <div>
                        <div>
                            <strong>Servicio: </strong><span>{{ configuredService.serviceDescription }}</span>
                        </div>
                        <div>
                            <strong>Cantidad: </strong><span>{{ getAmount(configuredService) }}</span>
                        </div>
                        <div>
                            <strong>Dirección: </strong><span>{{ configuredService.customerData ?
        getServiceAddressText(configuredService.customerData.address) : '' }}</span>
                        </div>
                        <div>
                            <strong>Tipo de pago: </strong><span>{{
        getPaymentDescription(configuredService.paymentTypeId)
    }}</span>
                        </div>
                        <div v-if="configuredService.nextPreorderDate">
                            <strong>Fecha siguiente preorden: </strong><span>{{
        formatDate(configuredService.nextPreorderDate)
    }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="div-50-perc service-data-container">
                <h4>Datos de la suscripción</h4>
                <div class="active-toggle" v-if="configuredService && configuredService.registryUuid">
                    <strong>Activo</strong>
                    <span>
                        <toggle-button :value="active" @change="active = !active" class="ml-1" :width="35"
                            :key="active" />
                    </span>
                </div>
                <div class="form-group">
                    <label class="label-text">Tipo de suscripción</label>
                    <select class="full" v-model="recurrencePeriodType" @change="updateEndDate()"
                        v-c-tooltip="'Indica el tipo de periodo, puede ser Día, Semana o Mes'">
                        <option value="days">
                            Día
                        </option>
                        <option value="weeks">
                            Semana
                        </option>
                        <option value="months">
                            Mes
                        </option>
                    </select>
                </div>
                <div class="form-group" v-if="isWeekly">
                    <label class="label-text">Suscripción</label>
                </div>
                <div class="form-group weekly-days" v-if="isWeekly">
                    <!-- <div class="form-group client-weekly-container"> -->
                        <button 
                        v-bind:class="[
                        selectedDays.includes('Sunday') ? 
                            'form-control button-selected' : 'form-control button-unselected'
                        ]"
                        @click="toggleWeekday('Sunday')"
                        type="button">Domingo</button>
                    <!-- </div>
                    <div class="form-group client-weekly-container"> -->
                        <button 
                        v-bind:class="[
                        selectedDays.includes('Monday') ? 
                            'form-control button-selected' : 'form-control button-unselected'
                        ]"
                        @click="toggleWeekday('Monday')"
                        type="button">Lunes</button>
                    <!-- </div>
                    <div class="form-group client-weekly-container"> -->
                        <button 
                        v-bind:class="[
                        selectedDays.includes('Tuesday') ? 
                            'form-control button-selected' : 'form-control button-unselected'
                        ]"
                        @click="toggleWeekday('Tuesday')"
                        type="button">Martes</button>

                    <!-- </div>
                    <div class="form-group client-weekly-container"> -->
                        <button 
                        v-bind:class="[
                        selectedDays.includes('Wednesday') ? 
                            'form-control button-selected' : 'form-control button-unselected'
                        ]"
                        @click="toggleWeekday('Wednesday')"
                        type="button">Miércoles</button>

                    <!-- </div>
                    <div class="form-group client-weekly-container"> -->
                        <button 
                        v-bind:class="[
                        selectedDays.includes('Thursday') ? 
                            'form-control button-selected' : 'form-control button-unselected'
                        ]"
                        @click="toggleWeekday('Thursday')"
                        type="button">Jueves</button>
                    <!-- </div>
                    <div class="form-group client-weekly-container"> -->
                        <button 
                        v-bind:class="[
                        selectedDays.includes('Friday') ? 
                            'form-control button-selected' : 'form-control button-unselected'
                        ]"
                        @click="toggleWeekday('Friday')"
                        type="button">Viernes</button>
                    <!-- </div>
                    <div class="form-group client-weekly-container"> -->
                        <button
                        v-bind:class="[
                        selectedDays.includes('Saturday') ? 
                            'form-control button-selected' : 'form-control button-unselected'
                        ]"
                        @click="toggleWeekday('Saturday')"
                        type="button">Sábado</button>
                    <!-- </div> -->
                </div>
                <div class="form-group" v-if="!isWeekly">
                    <label class="label-text">Suscripción</label>
                    <input name="selRecurrence" class="form-control input-group" placeholder="Periodo de suscripción"
                        v-model="recurrencePeriod" type="tel" pattern="[0-9]{3}" maxlength="3" required />
                </div>
                <div class="form-group">
                    <label class="label-text">Inicio suscripción</label>
                    <div class="input-group">
                        <date-range-picker ref="picker" v-model="startDate"
                            :locale-data="{ format: 'dd/mm/yyyy', daysOfWeek: daysOfWeekSpanish, applyLabel: 'Aplicar', cancelLabel: 'Cancelar' }"
                            :ranges="false" :single-date-picker="true" opens="center" :append-to-body="false"
                            class="date-range" :date-format="disabledDates" v-c-tooltip="'Fecha Inicio de suscripción'">
                            <template v-slot:input="picker" style="min-width: 350px;">
                                {{ formatToDatePicker(startDate.startDate, picker) }}
                            </template>
                        </date-range-picker>
                    </div>
                </div>
                <div class="form-group">
                    <label class="label-text">Fin suscripción</label>
                    <div class="input-group">
                        <date-range-picker ref="picker" v-model="endDate"
                            :locale-data="{ format: 'dd/mm/yyyy', daysOfWeek: daysOfWeekSpanish, applyLabel: 'Aplicar', cancelLabel: 'Cancelar' }"
                            :ranges="false" :single-date-picker="true" opens="center" :append-to-body="false"
                            class="date-range" :date-format="disabledDates" v-c-tooltip="'Fecha Inicio de suscripción'">
                            <template v-slot:input="picker" style="min-width: 350px;">
                                {{ formatToDatePicker(endDate.startDate, picker) }}
                            </template>
                        </date-range-picker>
                    </div>
                </div>
                <div class="form-group">
                    <label class="label-text">Hora</label>
                    <v-select label="displayTime" :options="time" v-model="selectedTime">
                        <template #search="{ attributes, events }">
                            <input class="vs__search" :required="!selectedTime" v-bind="attributes" v-on="events" />
                        </template>
                    </v-select>
                </div>
                <div class="form-group" v-if="configuredService && configuredService.registryUuid">
                    <strong>Saltar siguiente suscripción:</strong><span>
                        <toggle-button :value="skipNext" class="ml-1" :width="35" :key="skipNext"
                            @change="skipNext = !skipNext" />
                    </span>
                </div>
            </div>
        </form>

        <form novalidate v-show="configuringService" id="createRecurrence" ref="form" v-on:submit.prevent="onSubmit">
            <new-service v-bind:service-detail="selectedService" v-bind:options="newServiceOptions"
                v-bind:fetchService="fetchService" @onServiceConfigured="serviceConfigured"
                @onServiceConfiguredError="serviceConfiguredError"></new-service>
        </form>
        <template v-slot:footer>
            <CSpinner v-if="isRequesting" color="warming" size="sm" />
            <div v-else>
                <button type="button" class="btn btn-secondary mr-1" :disabled="isRequesting" @click="onCancel">
                    {{ configuringService ? 'Atras' : 'Cerrar' }}
                </button>
                <button type="submit" :disabled="!isFormValid()" form="createRecurrence" class="btn btn-primary ml-1"
                    v-c-tooltip="'Crea la suscripción con los datos ingresados'">
                    {{configuringService ? 'Seleccionar' : 'Finalizar'}}
                </button>
            </div>
        </template>
    </CModal>
</template>

<script>
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import NewService from '../services/NewService'
import {
    MEXICO_CITY_TIMEZONE,
} from '../../common/const'
import {
    PAYMENT_TYPES,
    SERVICE_ORIGIN,
    RECURRENCE_PERIOD_TYPE,
    DEVICES
} from '../../common/enums'
import {
    ERRORS_MESSAGES
} from '../../common/errors.js'

const fields = [
    { key: 'folio', label: 'Folio', sorter: false },
    { key: 'serviceCategory', label: 'Tipo de servicio', sorter: false },
    { key: 'units', label: 'Unidades', sorter: false },
    { key: 'serviceDate', label: 'Fecha del servicio', sorter: false }
]

const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export default {
    name: 'CreateRecurrenceModal',
    computed: {
    },
    watch: {
    },
    components: {
        vSelect,
        DateRangePicker,
        NewService
    },
    props: {
        isModalActive: {
            type: Boolean,
            required: true
        },
        infoModal: {}
    },
    async mounted() {
        await this.getSchedules()
        if (this.infoModal && this.infoModal.registryUuid) {
            this.configuredService = this.infoModal
            this.customerPhoneNumber = this.infoModal.customerPhoneNumber
            if (isNaN(this.infoModal.recurrencePeriod)) {
                if (this.infoModal.recurrencePeriodType == 'weeks') {
                    this.recurrencePeriod = +this.infoModal.recurrencePeriod.periods.days[0] / 7
                }
                if (this.infoModal.recurrencePeriod.periods.days) {
                    if (this.infoModal.recurrencePeriod.periods.days && isNaN(this.infoModal.recurrencePeriod.periods.days[0])) {
                        this.isWeekly = true
                        this.selectedDays = this.infoModal.recurrencePeriod.periods.days
                        // this.recurrencePeriod = weekdays.indexOf(this.infoModal.recurrencePeriod.periods.days[0])
                    } else {
                        this.recurrencePeriod = this.infoModal.recurrencePeriod.periods.days[0]
                    }
                } else {
                    this.recurrencePeriod = this.infoModal.recurrencePeriod.periods.period
                }
            } else {
                this.recurrencePeriod = this.infoModal.recurrencePeriod
            }
            this.recurrencePeriodType = this.infoModal.recurrencePeriodType == 'month' ?
                'months' : this.infoModal.recurrencePeriodType == 'week' ?
                    'weeks' :
                    this.infoModal.recurrencePeriodType

            this.startDate.startDate = this.infoModal.startDate
            this.endDate.startDate = this.infoModal.endDate
            this.selectedTime = this.time.find(obj => obj.hora === this.infoModal.preferredTime)
            this.active = this.infoModal.active
            this.infoModal.customerData.address = this.infoModal.address
            this.infoModal.amount = this.infoModal.details[0].units
            this.hasConfiguredService = true
        }
    },
    data: () => ({
        isWeekly: false,
        selectedDays: [],
        skipNext: false,
        active: true,
        recurrencePeriodType: 'days',
        recurrencePeriod: 1,
        hasConfiguredService: false,
        configuredService: {},
        fetchService: false,
        newServiceOptions: {
            disableCoupon: true,
            disableTip: true,
            hideDate: true,
            disableCustomerName: true,
            disableCustomerPhoneNumber: true
        },
        noItemsView: {
            noResults: 'Registros no encontrados.',
            noItems: 'Registros no encontrados.'
        },
        daysOfWeekSpanish: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
        createServiceModal: {
            isActive: false
        },
        selectedTime: null,
        time: [],
        selectedService: {},
        configuringService: false,
        items: [],
        fields,
        totalRecords: 0,
        sort: {
            column: 'serviceDate',
            asc: false
        },
        isRequesting: false,
        isErrorResponse: false,
        customerServices: [],
        clientName: '',
        customerPhoneNumber: '',
        latestFetchedCustomer: '',
        startDate: {
            startDate: moment(),
            endDate: null
        },
        endDate: {
            startDate: moment().add(1, 'days'),
            endDate: null
        },
        error: null,
        hasError: false
    }),
    methods: {
        disableInput(deviceFrom, input) {
            return deviceFrom ? [DEVICES.iOS, DEVICES.Android].includes(deviceFrom.toString().toLowerCase()) : false
        },
        editService() {
            this.configuringService = true
            this.fetchService = false
            this.selectedService = this.infoModal
        },
        async fetchCustomerServices() {
            if (this.customerPhoneNumber.length < 10 || this.customerPhoneNumber === this.latestFetchedCustomer) return

            this.customerServices = []
            this.latestFetchedCustomer = this.customerPhoneNumber
            this.$axios.get(`/customer/${this.customerPhoneNumber}/latest?limit=10`).then(response => {
                const { data } = response

                if (data.length > 0) {
                    return this.customerServices = data
                }
                this.noItemsView.noItems = 'No se encontraron servicios recientes'
            }).catch(error => {
                this.noItemsView.noItems = 'Cliente no encontrado'
            })
        },
        onPaste(e) {
            e.preventDefault();
            const pastedText =
                e.clipboardData.getData('text/plain')

            this.customerPhoneNumber =
                pastedText.replace(/\D/g, '')
        },
        updateEndDate() {
            console.log(this.recurrencePeriodType)
            switch (this.recurrencePeriodType) {
                case RECURRENCE_PERIOD_TYPE.DAYS.name:
                    this.isWeekly = false
                    return this.endDate.startDate = moment().add(1, RECURRENCE_PERIOD_TYPE.DAYS.name)
                case RECURRENCE_PERIOD_TYPE.WEEKS.name:
                case RECURRENCE_PERIOD_TYPE.WEEK.name:
                    this.isWeekly = true
                    return this.endDate.startDate = moment().add(1, RECURRENCE_PERIOD_TYPE.WEEKS.name)
                case RECURRENCE_PERIOD_TYPE.MONTHS.name:
                case RECURRENCE_PERIOD_TYPE.MONTH.name:
                    this.isWeekly = false
                    return this.endDate.startDate = moment().add(1, RECURRENCE_PERIOD_TYPE.MONTHS.name)
            }
        },
        onRowClicked(item) {
            this.axios.get(`/services/${item.registryUuid}`).then(async (response) => {
                const { data } = response
                data.tip = 0
                data.promocode = ''
                data.origin = SERVICE_ORIGIN.ADMIN.name
                this.selectedService = data
                this.configuringService = true
                this.fetchService = false
            })
        },
        isFormValid() {
            if (this.disableInput(this.configuredService.deviceFrom)) return true
            let isValidForm = true

            if (this.isRequesting || (!this.hasConfiguredService && !this.configuringService)) {
                isValidForm = false
            }

            if (this.hasConfiguredService && this.configuredService) {
                return !this.disableInput(this.configuredService.deviceFrom)
            }

            if (
                !this.recurrencePeriod ||
                !this.selectedTime
            ) {
                isValidForm = false
            }

            return isValidForm
        },
        formatDateTime(date) {
            return moment.utc(date, 'YYYYMMDD HH:mm:ss').format('DD/MM/YYYY hh:mm A')
        },
        formatDate(date) {
            return moment.utc(date, 'YYYYMMDD HH:mm:ss').format('DD/MM/YYYY')
        },
        getUnits(item) {
            if (!item.serviceDetail) {
                return null
            } if (item.serviceDetail.length > 1) {

            } else if (item.serviceDetail.length) {
                return item.serviceDetail[0].units
            }

            return null
        },
        selectNewService() {
            this.hasConfiguredService = false
            this.configuredService = {}
            this.selectedService = null
        },
        serviceConfigured(service) {
            this.hasConfiguredService = true
            this.configuredService = service
        },
        serviceConfiguredError() {
            this.fetchService = false
            this.hasConfiguredService = false
            this.configuringService = true
        },
        getAmount() {
            if (!this.configuredService || !this.configuredService.details || !this.configuredService.details.length) return ''

            return this.configuredService.details[0].isCurrency ?
                `$${(this.configuredService.amount / 100).toFixed(2)}` :
                `${this.configuredService.details[0].units} - (${this.configuredService.details[0].description})`
        },
        async getSchedules(selectedDate = null) {
            try {
                const { data } = await this.$axios.get(`/schedules/available`)
                this.time = this.returnHoras(data.full);
                this.selectedTime = this.time[0]
            } catch (err) {
                console.error(err)
                this.isErrorResponse = true
            } finally {
                this.isRequesting = false
            }
        },
        getServiceAddressText(address) {
            if (!address) return ''
            return `${address.street} #${address.numExt} ${address.municipality} ${address.state} (${address.reference})`
        },
        toggleWeekday(weekday) {
            this.selectedDays.includes(weekday) ? this.selectedDays.splice(this.selectedDays.indexOf(weekday), 1) : this.selectedDays.push(weekday)
        },
        returnHoras(horas) {
            let horasReturn = [];

            if (!horas.length) return horasReturn

            for (let i = 0; i < horas.length; i++) {
                horasReturn.push({
                    displayTime: `${horas[i].minTime.slice(0, 5)}-${horas[i].value.slice(0, 5)}`,
                    hora: horas[i].value.slice(0, 5),
                    selected: "white",
                    recommended: horas[i].recommended,
                });
            }
            return horasReturn;
        },
        getPaymentDescription(paymentTypeId) {
            switch (+paymentTypeId) {
                case PAYMENT_TYPES.CASH:
                    return 'Efectivo'
                case PAYMENT_TYPES.CARD:
                    return 'Tarjeta (TDEB | TCR)'
                default:
                    return 'Terminal (TDEB | TCR)'
            }
        },
        onCancel(e, force = false) {
            if (force || (!force && !this.configuringService)) {
                return this.$emit('onClose')
            }
            this.configuringService = false
        },
        disabledDates(classes, date) {
            const selectedDate =
                moment(date).tz(MEXICO_CITY_TIMEZONE)

            if (!classes.disabled) {
                const validDate = this.getValidDate()

                classes.disabled = selectedDate < validDate
            }
            return classes
        },
        getValidDate() {
            try {
                let validDate =
                    moment().tz(MEXICO_CITY_TIMEZONE).startOf('day')

                if (this.serviceType && [
                    SERVICE_TYPES.WATER.ID,
                    SERVICE_TYPES.HOME_CLEANER.ID,
                    SERVICE_TYPES.UPHOLSTERY.ID,
                    SERVICE_TYPES.FUMIGATION.ID
                ].includes(+this.serviceType.id)) {
                    const addDays = ALLOWED_DAY_CONFIG[+this.serviceType.id] || 0
                    validDate = validDate.add(addDays, 'days').startOf('day')
                }

                return validDate
            } catch (error) {
                this.isErrorResponse = true
            }
        },
        formatToDatePicker(date) {
            return date ? (
                moment(date).tz(MEXICO_CITY_TIMEZONE).format('DD/MM/YYYY')
            ) : moment().tz(MEXICO_CITY_TIMEZONE).format('DD/MM/YYYY')
        },
        async onSubmit() {
            try {
                if (this.configuringService) {
                    this.fetchService = true
                    return this.configuringService = false
                }

                if (this.isRequesting) {
                    return null
                }

                this.isRequesting = true
                const newRecurrence = {
                    ...this.configuredService,
                    recurrencePeriod: this.isWeekly ? { periods : { period :1, days: this.selectedDays }, type: "week"} : this.recurrencePeriod,
                    recurrencePeriodType: this.recurrencePeriodType,
                    startDate: this.startDate.startDate,
                    endDate: this.endDate.startDate,
                    skipNext: this.skipNext,
                    preferredTime: this.selectedTime,
                    active: this.active
                }
                console.log(newRecurrence.recurrencePeriodType)

                if (this.infoModal && this.infoModal.registryUuid) {
                    newRecurrence.registryUuid = this.infoModal.registryUuid
                }

                await this.$axios[newRecurrence.registryUuid ? 'put' : 'post']('/recurrence', newRecurrence)
                    .then(response => {
                        const { data } = response
                        return this.$emit('onSuccess')
                    }).catch(error => {
                        if (error.response &&
                            error.response.data &&
                            error.response.data.codeApi === ERRORS_MESSAGES.CUSTOMER_ALREADY_HAS_RECURRENCE.codeApi) {
                            this.hasError = true
                            this.error = ERRORS_MESSAGES.CUSTOMER_ALREADY_HAS_RECURRENCE.message
                        }
                    })
                return this.$emit('onSuccess')
            } catch (error) {
                this.error = `No se pudo ${this.infoModal && this.infoModal.registryUuid ? 'actualizar' : 'crear'} la suscripción`
                this.hasError = true
            } finally {
                this.isRequesting = false
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.m1-1 {
    margin-top: 6px
}

.service-control-buttons {
    position: absolute;
    right: 50%;
    margin-top: -35px;
}

.active-toggle {
    position: absolute;
    right: 5%;
    margin-top: -25px;
}

input.full,
select.full {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.map>* {
    z-index: 9999
}

.clear {
    clear: left;
}

.client-data-third-container {
    float: left;
    width: 33.3%;
}
.weekly-days{
    display: inline-flex;
}
.weekly-days button {
    border-left: none;
    border-right: none;
    border-radius: 0;
}
.weekly-days :first-child {
  border-left: 1 solid;
  border-radius: 0.8rem 0 0 0.8rem;
}
.weekly-days :last-child {
  border-right: 1 solid;
  border-radius: 0 0.8rem 0.8rem 0;
}
.label-text {
    color: #757575;
}

.client-data-container {
    float: left;
    width: 50%;
}

.service-data-container {
    padding: 0px 30px !important;
}

.showcase-container {
    margin-bottom: 5px;
}

.summary-subtotal {
    position: relative;
    top: -4px;
}

.summary-total {
    font-weight: 500;
    font-size: 25px
}

.button-unselected {
    color: #757575 !important;
}

.button-selected {
    background-color: #68d0d0 !important;
    color: #3d4964 !important;
    font-weight: bold;
}

.showcase-button-selected {
    background-color: #68d0d0 !important;
    color: #3d4964 !important;
}

.showcase-button {
    background-color: #f5f5f5;
    color: #757575;
    font-weight: bold;
    margin-right: 10px;
}

.service-type-container {
    width: 95%
}

.summary-details {
    background-color: #f5f5f5;
    border-radius: 4px;
    min-height: 140px;
    padding: 15px !important;
}

.div-50-perc {
    width: 50%;
    padding: 0%;
    margin: 0%;
    height: 100%;
    float: left;
}

button.close {
    color: black !important;
}

.form-group label {
    font-weight: bold;
}

.icon {
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg) brightness(96%) contrast(104%);
}

.amount-container {
    display: inline-block;
    width: 93%;
}

.subtotal-container {
    display: inline-block;
}

.inline-container {
    display: inline-block;
    width: 97%;
}

.date-range {
    width: 100%;
}

.error {
    color: red;
}

input:focus:invalid {
    border-color: red;
}
</style>