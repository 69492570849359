import { USER_TYPES } from '../common/enums'

export default [{
  _name: 'CSidebarNav',
  _children: [{
    _name: 'CSidebarNavTitle',
    _children: ['Servicios']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Lista de servicios',
    to: '/services',
    icon: 'cilList'
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Servicios restaurantes',
    to: '/servicestoctoc',
    icon: 'cilRestaurant',
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Contracargos',
    to: '/chargebacks',
    icon: 'cilList',
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Calificaciones',
    to: '/rating-comments',
    icon: 'cilList'
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Cancelados',
    to: '/cancelled',
    icon: 'cilList',
    roles: [USER_TYPES.COMPANY_ADMIN]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Suscripción',
    to: '/recurrence',
    icon: 'cilList',
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Preorden',
    to: '/preorder',
    icon: 'cilList',
    badge: { color: 'danger', shape: 'pill' },
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavTitle',
    _children: ['Recursos']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Lista de colaboradores',
    to: '/providers',
    icon: 'cilUser'
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Lista de Carrusel',
    to: '/carrusel',
    icon: 'cilUser',
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Cuentas',
    to: '/accounts',
    icon: 'cilUser',
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Créditos',
    to: '/credits',
    icon: 'cilUser',
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Lista de clientes',
    to: '/customers',
    icon: 'cilUser',
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Lista negra',
    to: '/black-list',
    icon: 'cilUser',
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Logs',
    to: '/logs',
    icon: 'cilList',
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Lista de compañías',
    to: '/companies',
    icon: 'cilBuilding',
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Lista de tipos de servicios',
    to: '/servicetypes',
    icon: 'cilBuilding',
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Productos',
    to: '/products',
    icon: 'cilBuilding',
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Precios de Producto',
    to: '/productPrice',
    icon: 'cilBuilding',
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Mapa',
    to: '/map',
    icon: 'cibOpenstreetmap'
  },
  {
    _name: 'CSidebarNavTitle',
    _children: ['Herramientas'],
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Administradores',
    to: '/admins',
    icon: 'cilUserFollow',
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Códigos promocionales',
    to: '/promocodes',
    icon: 'cilMoney',
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Lista de sensores',
    to: '/sensors',
    icon: 'cilList',
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavTitle',
    _children: ['Notificaciones'],
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Personalizada',
    to: '/notifications',
    icon: 'cilCommentSquare',
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Masiva',
    to: '/masive-notifications',
    icon: 'cilCommentSquare',
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavTitle',
    _children: ['Reportes'],
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Órdenes de servicio',
    to: '/services-report',
    icon: 'cilChartLine',
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Distribución de ventas',
    to: '/sales-distribution',
    icon: 'cilChartLine',
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'KPI\'s',
    to: '/charts',
    icon: 'cilChartLine',
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Performance de órdenes',
    to: '/service-order-performance',
    icon: 'cilChartLine',
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'KPI colaborador',
    to: '/kpi-provider',
    icon: 'cilChartLine',
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'KPI cliente',
    to: '/kpi-client',
    icon: 'cilChartLine',
    roles: [USER_TYPES.GENERAL_ADMIN]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Cerrar sesión',
    to: '/logout',
    icon: 'cilAccountLogout'
  }
  ]
}]
