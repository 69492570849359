<template>
    <div class="parent">
        <ReactSiteFrame id="site" :src="src" :keepSidebar="true" />
    </div>
</template>
  
<script>
import ReactSiteFrame from '../components/react/iframe'
import "amazon-connect-streams"

export default {
    name: 'ConnectReact',
    components: {
        ReactSiteFrame
    },
    data() {
        return {
            src: '/view/connect',
            contact: {
            }
        }
    },
    async beforeDestroy() {
        try {
            document.getElementById('ccp').style.display = 'none'
        } catch (e) { console.log(e) }
    },
    mounted() {
        try {
            document.getElementById('ccp').style.display = 'block'
            if (!this.contact.CustomerNumber) {
                document.getElementById('reactSite').addEventListener('load', () => {
                    this.sendContactAttributes(JSON.parse(localStorage.getItem('contactData') || '{}'))
                })
            }
        } catch (ex) { }
    },
    methods: {
        sendContactAttributes(contactData) {
            const site = document.getElementById('reactSite')

            if (site && site.contentWindow) {
                site.contentWindow.postMessage({ contactData }, '*')
                localStorage.setItem('contactData', JSON.stringify(contactData))
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.parent {
    display: flex;
    height: calc(100vh - 56px);
}

#site {
    margin-left: 30%;
    width: 70%;
}
@media screen and (max-width: 600px) {
  #site {
    margin-left: 0;
    width: 100%;
  }
}
</style>
